import { atom } from "recoil";
import { PAGE_ATOM_KEY } from "../../utils/constants";

const pageAtom = atom<{ pageTitle: string }>({
  key: PAGE_ATOM_KEY,
  default: {
    pageTitle: '',
  }
});

export default pageAtom; 
