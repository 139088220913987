/** @jsxImportSource @emotion/react */
import React from 'react';
import {
	MoreVert as MoreIcon,
	Mail as MailIcon,
	Notifications as NotificationsIcon,
	Logout as LogoutIcon,
	AccountCircle,
} from '@mui/icons-material';
import Button from '@mui/material/Button';
import { ActionItem } from './ActionItem';
import { useUserActions } from '../../hooks/useUserActions';
import { css } from '@emotion/react';
import { SIGNOUT } from '../../utils/constants';

interface ActionProps {
	total?: number;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	disableTooltip?: boolean;
}

export const Messages = ({ total, onClick, disableTooltip = false }: ActionProps) => (
	<ActionItem
		title='My Messages'
		icon={MailIcon}
		onClick={onClick}
		badgeContent={total}
		disableTooltip={disableTooltip}
	/>
);

export const More = ({ onClick, disableTooltip = false }: ActionProps) => (
	<ActionItem title='More' icon={MoreIcon} onClick={onClick} disableTooltip={disableTooltip} />
);

export const Notifications = ({ total, onClick, disableTooltip = false }: ActionProps) => (
	<ActionItem
		title='Notifications'
		icon={NotificationsIcon}
		onClick={onClick}
		badgeContent={total}
		disableTooltip={disableTooltip}
	/>
);

export const UserAccount = ({ onClick, disableTooltip = false }: ActionProps) => (
	<ActionItem title='My Account' icon={AccountCircle} onClick={onClick} disableTooltip={disableTooltip} />
);

export const SignOut = () => {
	const userActions = useUserActions();
	const onClick = () => {
		userActions.logout();
	};

	return (
		<>
			<Button
				variant='text'
				startIcon={<LogoutIcon />}
				disableFocusRipple={true}
				disableRipple
				onClick={onClick}
				css={css`
					&:hover {
						background-color: transparent !important;
					}
				`}>
				{SIGNOUT}
			</Button>
		</>
	);
};
