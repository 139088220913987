/**
 * Function that used to format number by adding digit and currency at the end
 * @param number number that need to format
 * @param locale locale name
 * @param currency currency 3 letters
 * @returns number with digit and currency letter after
 */
export const formatCurrency = (number: number, locale = 'vi', currency = 'VND'): string => {
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(number);
}

/**
 * Function that used to format number by adding DIGIT ONLY
 * @param number 
 * @param locale 
 * @param currency 
 * @returns 
 */
export const formatDecimal = (number: number, locale = 'vi', currency = 'VND') => {
  return new Intl.NumberFormat(locale, { style: 'decimal' }).format(number);
}