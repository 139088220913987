import { IPartners } from "../types/IPartners";

export const loadPartnerListUntilEmpty = async () => {
  //TODO: until API is ready, use this mock data
  let partnerList: IPartners[] = [
    {  partner_code: 'FHQ', partner_name: 'FintechHQ', is_active: true },
    {  partner_code: 'PA', partner_name: 'Partner A', is_active: true },
    {  partner_code: 'PB', partner_name: 'Partner B', is_active: true },
    {  partner_code: 'PC', partner_name: 'Partner C', is_active: false },
  ];
  
  return partnerList.filter(({ is_active: isActive }) => isActive);
};
