import Request from './Request';

type amount = number;

export interface IAccountBalanceHistory {
  account_number: string;
  account_name: string;
  bank_account_number: string;
  cif_type: string;
  cif_number: string;
  sme_number: string;
  business_id: string;
  bank_code: string;
  currency_code: '704' | '840';
  occurring_date: string;
  previous_current_amount: amount;
  balance_current_amount: amount;
  total_debit_amount: amount;
  total_credit_amount: amount;
  aggregated_amount: amount;
  aggregated_status: 'matched' | 'unmatched' | 'missed';
}

export interface IAccountBalanceSummary {
  total_record: number;
  total_debit_amount: amount;
  total_credit_amount: amount;
  balance_current_amount: amount;
  previous_current_amount: amount;
  aggregated_amount: amount;
};

export interface IGetAccountBalanceHistoryQueryParameters {
  acc?: string;
  name?: string;
  start?: string;
  end?: string;
  status?: 'matched' | 'unmatched' | 'missed';
  bank?: string;
  cif?: string;
  sme?: string;
  biz?: string;
  type?: string;
  limit?: number;
  page: number;
  date?: string;
}


const PATH = '/api/v1/banking/bo/balance';

export const getAccountBalanceHistory = (params: IGetAccountBalanceHistoryQueryParameters) => Request.get(`${PATH}/account/history`, { params });
export const getAccountBalanceSummary = (params: IGetAccountBalanceHistoryQueryParameters) => Request.get(`${PATH}/account/summary`, { params });

