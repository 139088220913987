import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { RecoilRoot } from 'recoil';


import { APP_TITLE, APP_DESCRIPTION } from './utils/constants';
import App from './App';
import Theme from './theme';
import LoadingIndicator from './components/LoadingIndicator';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		<HelmetProvider>
			<Helmet>
				<title>{APP_TITLE}</title>
				<meta name='description' content={APP_DESCRIPTION} />
				<link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap' />
				<meta name='viewport' content='initial-scale=1, width=device-width' />
			</Helmet>
		</HelmetProvider>
    <RecoilRoot>
      <Theme>
        <LoadingIndicator />
        <App />
      </Theme>
    </RecoilRoot>
	</React.StrictMode>,
);
