import { useRecoilState } from 'recoil';
import applicationStateAtom from '../recoil/atoms/applicationStateAtom';
import { extractReponse } from '../utils/responseExtractor';
import { IAssets, generateDownloadUrlForPresignLink } from '../services/Download';
import Logger from '../services/Logger';
import { generateReport, IGenerateReportParams } from '../services/Report';

export const useDownloadHandler = () => {
	const [applicationState, setApplicationState] = useRecoilState(applicationStateAtom);

	const exportAndDownloadData = async ({
    reportConditions,
    reportType,
  }: {
    reportType: IGenerateReportParams['report_type'];
    reportConditions: IGenerateReportParams['report_conditions'];
  }) => {
		try {
      setApplicationState({ ...applicationState, isLoading: true });

      const response = await generateReport({
        file_type: 'xlsx',
        report_type: reportType,
        report_conditions: reportConditions,
      });

      const url = extractReponse<{ file_url: string }[]>(response)[0].file_url;
      const generateDownLoadUrlResponse = await generateDownloadUrlForPresignLink(url);
      window.open(extractReponse<IAssets[]>(generateDownLoadUrlResponse)[0].download_url, '__blank');
    } catch (exception) {
      Logger.log(exception);
    } finally {
      setApplicationState({ ...applicationState, isLoading: false });
    }
	};

  return exportAndDownloadData;
};
