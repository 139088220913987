import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { PageDefault } from './components/PageDefault';
import { Login } from './pages/Login';
import { AppContext } from './contexts';
import { AppClient } from './clients';
import { routes } from './config';
import { Route as AppRoute } from './types';
import { useRecoilState } from 'recoil';
import applicationStateAtom from './recoil/atoms/applicationStateAtom';
import authStateAtom from './recoil/atoms/authStateAtom';
import sharedDataAtom from './recoil/atoms/sharedDataAtom';
import { useEffect } from 'react';
import { loadBankListUntilEmpty } from './utils/loadBankListUntilEmpty';
import { loadPartnerListUntilEmpty } from './utils/loadPartnerListUntilEmpty';
import { IBank } from './types/IBank';
import { IPartners } from './types/IPartners';
import { useUserActions } from './hooks/useUserActions';

function App() {
	const appClient = new AppClient();
  const [applicationState] = useRecoilState(applicationStateAtom);
  const [authState] = useRecoilState(authStateAtom);
  const [sharedDataState, setSharedDataState] = useRecoilState(sharedDataAtom);
  const userActions = useUserActions();

  useEffect(() => {
    const handleLogoutEvent = () => {    
      userActions.logout();
    };
    window.addEventListener('logout', handleLogoutEvent);
    return () => {
      window.removeEventListener('logout', handleLogoutEvent);
    };
  });


  useEffect(() => {
    const loadSharedData = async () => {
      const banks: IBank[] = await loadBankListUntilEmpty();
      const partners: IPartners[] = await loadPartnerListUntilEmpty();

      setSharedDataState({
        ...sharedDataState,
        banks,
        partners
      });
    };

    if (authState.isStatePersisted && authState.isLoggedIn) {
      loadSharedData();
    }
  }, [authState.isLoggedIn, authState.isStatePersisted]);

	const addRoute = (route: AppRoute) => {
		return (
		<Route index key={route.key} path={route.path} element={route.component ? <route.component/> : <PageDefault/>} />
	)};

  if (!applicationState.isStatePersisted) {
    return null;
  }

	if(!authState.isLoggedIn) {
    return <Login />
  }

	return (
		<AppContext.Provider value={appClient}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Layout />}>
						{routes.map((route: AppRoute) =>
							route.subRoutes ? route.subRoutes.map((item: AppRoute) => addRoute(item)) : addRoute(route)
						)}
					</Route>
				</Routes>
			</BrowserRouter>
		</AppContext.Provider>
	);
}

export default App;
