/** @jsxImportSource @emotion/react */
import { Box, Button, Card, Grid, Select, Stack, MenuItem, FormControl, InputLabel, Input, TextField, Link, Tooltip } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { GridColDef } from '@mui/x-data-grid';
import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
	APP_TITLE,
	DATE_FORMAT,
	DISPLAY_DATE_TIME_FORMAT,
	RESET,
	SEARCH,
	REFRESH,
  PAYMENT_METHOD_LABEL,
} from '../../utils/constants';
import { PAGE_TITLE } from './constants';
import Logger from '../../services/Logger';
import { formatDecimal } from '../../utils/formatCurrency';
import { Refresh } from '@mui/icons-material';
import { DownloadReport } from '../../components/Actions/DownloadReport';
import pageStateAtom from '../../recoil/atoms/pageStateAtom';
import { ITimeFilterRef } from '../../components/Filter/TimeFilter';
import { ActionItem } from '../../components/Actions/ActionItem';
import moment from 'moment';
import { CustomDataGrid } from '../../components/DataGrid/CustomDataGrid';
import { extractReponse } from '../../utils/responseExtractor';
import { useDownloadHandler } from '../../hooks/useDownloadHandler';
import { trimEmptyField } from '../../utils/trimEmptyField';
import { IPaymentLink, IPaymentLinkQuery, IPaymentLinkSummary, IRequestDetail, getPaymentLinkHistories, getPaymentLinkSummary } from '../../services/Payment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { CurrencyCode } from '../../types/Currency';


const PAYMENT_LINK_STATUSES = {
  'created': 'Đang hoạt động',
  'failed': 'Thất bại',
  'expired': 'Hết hạn',
  'success': 'Thành công',
  'reconciled': 'Đối soát thành công',
  'settled': 'Settled',
};

const INITIAL_VALUE: {
  paymentStatus: keyof typeof PAYMENT_LINK_STATUSES | ''
  paymentMethod: keyof typeof PAYMENT_METHOD_LABEL | '';
  startDate: moment.Moment;
  endDate: moment.Moment;
  paymentNumber: string;
} = {
  paymentStatus: '',
  paymentMethod: '',
  startDate: moment().startOf('month'),
  endDate: moment(),
  paymentNumber: '',
};

const mapValueToRequestPayload = (values: typeof INITIAL_VALUE) => {
  const { paymentStatus, paymentMethod, startDate, endDate, paymentNumber } = values;
  const payload: {
    expired?: IPaymentLinkQuery['expired'];
    status?: IPaymentLinkQuery['status'] | '';
    state?: IPaymentLinkQuery['state'] | '';
    start?: IPaymentLinkQuery['start'] | '';
    end?: IPaymentLinkQuery['end'] | '';
    method?: IPaymentLinkQuery['method'] | '',
    number?: IPaymentLinkQuery['number'] | '',
    link?: IPaymentLinkQuery['link'] | '',
  } = {
    method: paymentMethod,
    start: startDate.format(DATE_FORMAT).toString(),
    end: endDate.format(DATE_FORMAT).toString(),
    number: paymentNumber,
  };

  switch (paymentStatus) {
    case 'created':
      payload.expired = false;
      payload.state = 'unpaid';
      payload.status = 'created';
      break;

    case 'failed':
      payload.state = 'unpaid';
      payload.expired = true;
      break;

    case 'expired':
      payload.state = 'unpaid';
      payload.expired = true;
      break;

    case 'success':
      payload.status = 'success';
      break;

    case 'reconciled':
      payload.status = 'reconciled';
      break;
    
    case 'settled':
      payload.status = 'settled';
      break;
  }

  return trimEmptyField<IPaymentLinkQuery>(payload);
};

const mapListRequestPayloadToDownloadRequestPayload = (values: any) => {
  const {
    acc,
    bank,
    status,
    cif,
    type,
    start,
    end,
    date,
    sme,
  } = values;

  const ifUserChoiceDateRange = (_start: string, _end: string) => _start && _end;
  const today = moment().format(DATE_FORMAT).toString();
  
  return {
    account_number: acc,
    bank_code: bank,
    aggregated_status: status,
    cif_type: type,
    cif_number: cif,
    sme_number: sme,
    start_date: start || undefined,
    end_date: end || undefined,
    occurring_date: date || (ifUserChoiceDateRange(start, end) ? undefined : today),
  };
}

export const PaymentRequestList = () => {	
	const [pageState, setPageState] = useRecoilState(pageStateAtom);
	const [searchParams, setSearchParams] = useSearchParams();
  const [paymentLinks, setPaymentLinks] = useState<IPaymentLink[]>([])
  const [summary, setSummary] = useState<IPaymentLinkSummary | null>(null)
	const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const timeFilterRef = useRef<ITimeFilterRef>({ reset() {}, });
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 10,
		page: searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0,
	});
  const isApplyingFilterValue = useRef(searchParams.get('isApplyingFilterValue') || false);
  const exportAndDownload = useDownloadHandler();

  const columns: GridColDef<IPaymentLink & { index: number }>[] = useMemo(() => [
    {
      field: 'created_at',
      headerName: 'Thời gian thanh toán',
      flex: 1,
      minWidth: 200,
      valueFormatter(params) {
        if (params.value) {
          return moment(params.value).format(DISPLAY_DATE_TIME_FORMAT).toString();
        }
  
        return '';
      },
    },
    {
      field: 'payment_number',
      headerName: 'Mã thanh toán',
      minWidth: 250,
      renderCell(params) {
        const { row }: { row: IPaymentLink } = params;
        const paymentNumber = row?.request_details?.find(({ payment_number }) => payment_number)?.payment_number || '';  
        
        return (
          <Tooltip title='Giao dịch chưa được đối soát/settled' placement='right-end'>
            <span>{paymentNumber}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'linking_account_number',
      headerName: 'Số tài khoản',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      minWidth: 200,
    },
    {
      field: 'payment_link_amount',
      headerName: 'Số tiền',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      minWidth: 150,
      valueFormatter(params) {
        if (params.value) {
          return formatDecimal(params.value);
        }
  
        return ''
      },
    },
    {
      field: 'net_amount',
      headerName: 'Net amount',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      minWidth: 150,
      renderCell(params) {
        const { row }: { row: IPaymentLink } = params
        const requestDetails = row.request_details.find(({ settlement_details: settlementDetails }) => settlementDetails?.length);

        if (requestDetails) {

          return formatDecimal(requestDetails.net_amount);
        }

        return '-';
      }
    },
    {
      field: 'base_fee',
      headerName: 'Base fee',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      minWidth: 150,
      renderCell(params) {
        const { row }: { row: IPaymentLink } = params
        const requestDetails = row.request_details.find(({ settlement_details: settlementDetails }) => settlementDetails?.length);

        if (requestDetails) {
          return formatDecimal(requestDetails.base_fee_amount);
        }

        return '-';
      }
    },
    {
      field: 'markup_fee',
      headerName: 'Markup fee',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      minWidth: 150,
      renderCell(params) {
        const { row }: { row: IPaymentLink } = params
        const requestDetails = row.request_details.find(({ settlement_details: settlementDetails }) => settlementDetails?.length);

        if (requestDetails) {
          return formatDecimal(requestDetails.markup_fee_amount);
        }

        return '-';
      }
    },
    {
      field: 'currency_code',
      headerName: 'Đơn vị',
      align: 'center',
      headerAlign: 'center',
      valueFormatter(params) {
        return CurrencyCode[params.value as keyof typeof CurrencyCode];
      },
    },
    {
      field: 'payment_link_status',
      headerName: 'Trạng thái thanh toán',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      minWidth: 200,
      renderCell(params) {
        const { row }: { row: IPaymentLink } = params;
        const today = moment();
        const expiredAt = moment(row.expired_at);
  
        if (!row.payment_link_status) {
          return '-';
        }
  
        if (
          ['extra_paid', 'paid', 'partial_paid'].includes(row.payment_link_status)
        ) {
          switch (row?.request_details?.find((requestDetail) => requestDetail.payment_request_status)?.payment_request_status) {
            case 'reconciled':
              return PAYMENT_LINK_STATUSES.reconciled;
  
            case 'settled':
              return PAYMENT_LINK_STATUSES.settled;
  
            case 'success':
              return PAYMENT_LINK_STATUSES.success;
          }
        } else if (row.payment_link_status === 'unpaid') {
          if (today.isAfter(expiredAt)) {
            return PAYMENT_LINK_STATUSES.expired;
          }
  
          switch(row?.request_details?.find((requestDetail) => requestDetail.payment_request_status)?.payment_request_status) {
            case 'created':
              return PAYMENT_LINK_STATUSES.created;
              
            default:
              return PAYMENT_LINK_STATUSES.failed;
          }
        }
      },
    },
    {
      field: 'reconciled_at',
      headerName: 'Thời gian đối soát',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      minWidth: 200,
      renderCell(params) {
        const { row }: { row: IPaymentLink } = params;
        const reconcileAt: string | undefined = row.request_details?.find((requestDetails) => {
          return requestDetails.reconciled_at;
        })?.reconciled_at;
  
        return reconcileAt ? moment(reconcileAt).format(DISPLAY_DATE_TIME_FORMAT) : '-';
      },
    },
    {
      field: 'payment_at',
      headerName: 'Thời gian settled',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      minWidth: 200,
      renderCell(params) {
        const { row }: { row: IPaymentLink } = params;
        let settleAt: string | undefined;
  
        if (row.request_details?.length) {
          row
            .request_details
            .forEach((request_details) => {
              if (settleAt) {
                return;
              }
  
              settleAt = request_details.settlement_details.find(settlementDetail => settlementDetail.fee_type === 'net_trx')?.payment_at;
            });
        }
  
        return settleAt ? moment(settleAt).format(DISPLAY_DATE_TIME_FORMAT) : '-';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      minWidth: 80,
      renderCell(params) {
        const { row }: { row: IPaymentLink } = params;
  
        const urlParams: { [key: string]: string } = {};
        Array.from(searchParams.entries()).forEach(([key, value]) => {
          urlParams[key] = value;
        });

        const startDate = moment(urlParams['startDate']).format(DATE_FORMAT).toString();
        const endDate = moment(urlParams['endDate']).format(DATE_FORMAT).toString();
        const requestDetail = row?.request_details?.find(({ payment_request_status: paymentRequestStatus }) => ['settled', 'reconciled'].includes(paymentRequestStatus));

        if (requestDetail) {
          return (
            <Button
              onClick={() => {
                navigate(`/transaction-history?page=1&limit=10&isFiltering=true&start=${startDate}&end=${endDate}&payment_numbers=${requestDetail?.settlement_details.map(({ payment_number }) => payment_number).join(',')}`)
              }}
              size='small'
              variant='outlined'>
              Chi tiết
            </Button>
          );
        }
  
  
        return '';
      },
    },
  ], []);

	const fetchData = useCallback(
		async (params: IPaymentLinkQuery) => {
			try {
				setIsLoading(true);

				const response = await getPaymentLinkHistories({
          ...params,
          vendor: 'GAL'
        });
        const summaryResponse = await getPaymentLinkSummary({
          ...params,
          vendor: 'GAL'
        });
        const paymentLinks = extractReponse<IPaymentLink[]>(response);
        const summary = extractReponse<IPaymentLinkSummary[]>(summaryResponse)

        setPaymentLinks((paymentLinks || []).map((paymentLink, index) => ({
          ...paymentLink,
          index: (params.page! * index) + 1,
        })));
        setSummary(summary?.[0]);
			} catch (exception) {
				Logger.log(exception);
			} finally {
				setIsLoading(false);
			}
		},
		[],
	);

	const formik = useFormik<typeof INITIAL_VALUE>({
		initialValues: {
      startDate: searchParams.get('startDate') ? moment(searchParams.get('startDate')) : INITIAL_VALUE.startDate,
      endDate: searchParams.get('endDate') ? moment(searchParams.get('endDate')) : INITIAL_VALUE.endDate,
      paymentMethod: (searchParams.get('paymentMethod') || INITIAL_VALUE.paymentMethod) as  typeof INITIAL_VALUE.paymentMethod,
      paymentStatus: (searchParams.get('paymentStatus') || INITIAL_VALUE.paymentStatus) as typeof INITIAL_VALUE.paymentStatus,
      paymentNumber: searchParams.get('paymentNumber') || INITIAL_VALUE.paymentNumber,
    },
		onSubmit: () => {
      isApplyingFilterValue.current = true;
			onChangePage({
				page: 0,
				pageSize: 10,
			});
		},
	});

	useEffect(() => {
		setPageState({
			...pageState,
			pageTitle: PAGE_TITLE,
		});
    console.log(formik.values);
		fetchData({
			page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      ...(isApplyingFilterValue.current ? mapValueToRequestPayload(formik.values) : {
        start: INITIAL_VALUE.startDate.format(DATE_FORMAT).toString(),
      }),
		});
    setSearchParams(trimEmptyField({
			page: paginationModel.page + 1,
      isApplyingFilterValue: isApplyingFilterValue.current,
      ...(isApplyingFilterValue.current ? formik.values : {
        startDate: INITIAL_VALUE.startDate.format(DATE_FORMAT).toString(),
        endDate: INITIAL_VALUE.endDate.format(DATE_FORMAT).toString(),
      })
		}));
	}, [fetchData]);

	const onChangePage = (nextPaginationModel: typeof paginationModel) => {
		setPaginationModel(nextPaginationModel);

		fetchData({
			page: nextPaginationModel.page + 1,
      limit: nextPaginationModel.pageSize,
      ...(isApplyingFilterValue.current ? mapValueToRequestPayload(formik.values) : {
        start: INITIAL_VALUE.startDate.format(DATE_FORMAT).toString(),
        end: INITIAL_VALUE.endDate.format(DATE_FORMAT).toString(),
      }),
		});
		setSearchParams(trimEmptyField({
			page: nextPaginationModel.page + 1,
      isApplyingFilterValue: isApplyingFilterValue.current,
      ...(isApplyingFilterValue.current ? formik.values : {
        startDate: INITIAL_VALUE.startDate.format(DATE_FORMAT).toString(),
      })
		}));
	};

	const handleDownloadReport = () => {
    alert('API chưa support');
    // exportAndDownload({
    //   reportConditions: (isApplyingFilterValue.current ? mapListRequestPayloadToDownloadRequestPayload(mapValueToRequestPayload(formik.values)) : {
    //     occurring_date: moment().format(DATE_FORMAT).toString(),
    //   }),
    //   reportType: 'va_balance_history',
    // });
	};

	const handleFilterReset = () => {
		formik.setValues(INITIAL_VALUE);
    isApplyingFilterValue.current = false;
    timeFilterRef.current.reset();
    onChangePage({
      page: 0,
      pageSize: 10,
    });
	}

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>
						{PAGE_TITLE} | {APP_TITLE}
					</title>
				</Helmet>
			</HelmetProvider>
			<Card sx={{ padding: 2, paddingTop: 3 }}>
				<Box sx={{ display: 'grid', gap: 3, gridTemplateColumns: 'repeat(4, 1fr)' }}>
					<FormControl size='small' sx={{ minWidth: 150 }} >
						<InputLabel id='payment-link-status'>Trạng thái thanh toán</InputLabel>
						<Select
              labelId='payment-link-status' autoWidth label="Trạng thái thanh toán"
              {...formik.getFieldProps('paymentStatus')}
            >
							{Object.entries(PAYMENT_LINK_STATUSES).map(([value, label]) => (
								<MenuItem key={value as string} value={value}>
									{label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
          <FormControl size='small' sx={{ minWidth: 150 }}>
						<InputLabel id='status-selection'>Phương thức thanh toán</InputLabel>
						<Select
              labelId='status-selection'
              autoWidth
              label="Phương thức thanh toán"
              {...formik.getFieldProps('paymentMethod')}
            >
							{Object.entries(PAYMENT_METHOD_LABEL).map(([value, label]) => (
								<MenuItem key={value} value={value}>
									{label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
          <FormControl sx={{ minWidth: 150 }}>
						<TextField fullWidth size='small' label="Mã thanh toán" {...formik.getFieldProps('paymentNumber')}/>
					</FormControl>
          <FormControl>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								maxDate={moment()}
								format={DATE_FORMAT}
								label="Ngày bắt đầu"
								value={formik.values.startDate}
								onChange={(value) => formik.setFieldValue('startDate', value)}
								slotProps={{ textField: { size: 'small' } }}
							/>
						</LocalizationProvider>
					</FormControl>
          <FormControl>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								maxDate={moment()}
                minDate={formik.values.startDate}
								format={DATE_FORMAT}
								label="Ngày kết thúc"
								value={formik.values.endDate}
								onChange={(value) => formik.setFieldValue('endDate', value)}
								slotProps={{ textField: { size: 'small' } }}
							/>
						</LocalizationProvider>
					</FormControl>
					{/* <TimeFilter
            ref={timeFilterRef}
            enableDateRangeForCustomOption
            handleFilterOptionChange={() => {}}
            initialValue={formik.values.selectedTimeFilter as FilterEnum.Today}
          /> */}
				</Box>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(1, 1fr)',
						gap: 1,
						mt: 1,
					}}>
					<Stack direction='row' spacing={2} sx={{ pt: 1, pb: 1 }} alignItems='right' justifyContent={'flex-end'}>
						<Button variant='outlined' sx={{ mr: 2 }} onClick={handleFilterReset}>
							{RESET}
						</Button>
						<Button variant='contained' onClick={formik.submitForm}>
							{SEARCH}
						</Button>
					</Stack>
				</Box>
			</Card>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					mb: 2,
					mt: 2,
				}}>
        <Box />
				<Box>
					<DownloadReport
						handleDownloadReport={handleDownloadReport}
					/>
					<ActionItem title={REFRESH} icon={Refresh} onClick={() => onChangePage(paginationModel)} />
				</Box>
			</Box>
			<Grid container spacing={3}>
				<Grid item xs={12} md={12} lg={12}>
					<Card>
						<CustomDataGrid
							tableKey='PAYMENT_LINK_TABLE'
							loading={isLoading}
							rowCount={summary?.total_transaction || 0}
							rows={paymentLinks}
							columns={columns}
							getRowId={(row: IPaymentLink) => row.payment_link_number}
							paginationModel={paginationModel}
							onPaginationModelChange={onChangePage}
              pageSizeOptions={[10, 50, 100]}
							summaryRows={[
								{ key: 'total_base_fee_amount', value: formatDecimal(summary?.total_base_fee_amount || 0) },
								{ key: 'total_markup_fee_amount', value: formatDecimal(summary?.total_markup_fee_amount || 0) },
                { key: 'total_net_amount', value: formatDecimal(summary?.total_net_amount || 0) },
                { key: 'total_paid_amount', value: formatDecimal(summary?.total_paid_amount || 0) },
                { key: 'total_payment_amount', value: formatDecimal(summary?.total_payment_amount || 0) },
                { key: 'total_transaction', value: formatDecimal(summary?.total_transaction || 0) },
							]}
						/>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};

export default PaymentRequestList;
