import { IconButton, Tooltip, Box } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';

export const CustomColumnHeader = (header: string, tooltip: string) => {
	return (
		<Box sx={{ display: 'flex' }}>
			<Tooltip title={header}>
				<Box sx={{whiteSpace: 'normal', lineHeight:1.5, textAlign:'center'}}>{header}</Box>
			</Tooltip>
			<Tooltip title={tooltip}>
				<IconButton size='small' sx={{height: 30, width: 30}}>
					<InfoIcon fontSize='inherit' />
				</IconButton>
			</Tooltip>
		</Box>
	);
};
