/** @jsxImportSource @emotion/react */
import {
	Box,
	Button,
	Card,
	Grid,
	Select,
	Stack,
	TextField,
	Typography,
	MenuItem,
	FormControl,
	InputLabel,
	Chip,
} from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { GridColDef } from '@mui/x-data-grid';
import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Search } from '../../components/Filter/Search';
import {
	APP_TITLE,
	DATE_FORMAT,
	DISPLAY_DATE_TIME_FORMAT,
	CURRENCY,
	TOTAL_DEBIT,
	TOTAL_CREDIT,
	RESET,
	SEARCH,
	TRADING_START_DATE,
	TRADING_END_DATE,
	FIND_TRANSACTION,
  LAST_UPDATED_AT,
  REFRESH,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE
} from '../../utils/constants';
import { extractReponse } from '../../utils/responseExtractor';
import { ITransactionQuery, getTransactions, getTransactionsSummary, ITransaction } from '../../services/Transactions';
import Logger from '../../services/Logger';
import { ITransactionSummary } from '../../types/ITransactionSummary';
import { useSearchParams } from 'react-router-dom';
import { EPaymentUsecase } from '../../types/EPaymentUsecase';
import sharedDataAtom from '../../recoil/atoms/sharedDataAtom';
import { formatDecimal } from '../../utils/formatCurrency';
import { EPaymentStatus } from '../../types/EPaymentStatus';
import { useFormik } from 'formik';
import { ActionItem } from '../../components/Actions/ActionItem';
import { Refresh } from '@mui/icons-material';
import { PAGE_TITLE } from './constants';
import pageStateAtom from '../../recoil/atoms/pageStateAtom';
import { CurrencyCode } from '../../types/Currency';
import { CustomDataGrid } from '../../components/DataGrid/CustomDataGrid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useDownloadHandler } from '../../hooks/useDownloadHandler';
import { DownloadReport } from '../../components/Actions/DownloadReport';
import { trimEmptyField } from '../../utils/trimEmptyField';

const paymentStatusMap = {
	[EPaymentStatus.Success]: 'Thành công',
	[EPaymentStatus.Failed]: 'Thất bại',
	[EPaymentStatus.Pending]: 'Đang xử lý',
	[EPaymentStatus.Settling]: 'Chờ xử lý',
	[EPaymentStatus.Expired]: 'Hết hạn',
};

const paymentUseCaseMap = {
  [EPaymentUsecase.P01]: 'Thu',
  [EPaymentUsecase.P02]: 'Chi',
  [EPaymentUsecase.P03]: 'Nội bộ',
  [EPaymentUsecase.P10]: 'GalaxyPay',
};

const mapValueToListApiPayload = (values: any) => {
  const { accountName, accountNumber, transactionNumber, status, not, paymentUseCase, paymentNumbers } = values;


  return {
    account: accountNumber,
    name: accountName,
    trx: transactionNumber,
    status: status,
    not: not,
    case: paymentUseCase,
    pays: paymentNumbers,
  };
};

const mapValueToDownloadApiPayload = (values: any) => {
  const {
    account,
    name,
    trx,
    status,
    not,
    case: paymentUseCase,
  } = values;

  return {
    account_number: account,
    account_name: name,
    transaction_number: trx,
    payment_status: status,
    non_payment_status: not,
    payment_use_case: paymentUseCase,
  };
};

const columns: GridColDef[] = [
	{
		field: 'transaction_at',
		headerName: 'Ngày phát sinh giao dịch',
		minWidth: 200,
		type: 'dateTime',
		valueFormatter(params) {
			return moment(params.value).format(DISPLAY_DATE_TIME_FORMAT).toString();
		},
	},
	{
		field: 'transaction_number',
		headerName: 'Mã giao dịch hệ thống',
		minWidth: 300,
	},
  {
		field: 'payment_number',
		headerName: 'Mã thanh toán',
		minWidth: 300,
	},
	{
		field: 'bank_transaction_number',
		headerName: 'Mã giao dịch ngân hàng',
		minWidth: 250,
	},
	{
		field: 'account_name',
		headerName: 'Đối tác',
		minWidth: 200,
	},
	{
		field: 'payment_use_case',
		headerName: 'Loại giao dịch',
		minWidth: 200,
		valueFormatter(params) {

			return paymentUseCaseMap[params.value as keyof typeof paymentUseCaseMap];
		},
	},
	{
		field: 'beneficiary_bank_code',
		headerName: 'Ngân hàng thụ hưởng',
		minWidth: 200,
	},
	{
		field: 'account_number',
		headerName: 'Tài khoản thanh toán',
		minWidth: 200,
	},
	{
		field: 'NA',
		headerName: 'Ghi nợ',
		minWidth: 160,
		align: 'right',
		headerAlign: 'right',
		valueGetter(params) {
			const { row }: { row: ITransaction } = params;

			if (row.transaction_type === 'DR') {
				return row.transaction_amount;
			}

			return '';
		},
		valueFormatter(params) {
			return params.value && formatDecimal(params.value);
		},
	},
	{
		field: 'NA1',
		headerName: 'Ghi có',
		minWidth: 160,
		align: 'right',
		headerAlign: 'right',
		valueGetter(params) {
			const { row }: { row: ITransaction } = params;

			if (row.transaction_type === 'CR') {
				return row.transaction_amount;
			}

			return '';
		},
		valueFormatter(params) {
			return params.value && formatDecimal(params.value);
		},
	},
	{
		field: 'currency_code',
		headerName: CURRENCY,
		align: 'center',
		headerAlign: 'center',
		valueFormatter(params) {
			return CurrencyCode[params.value as keyof typeof CurrencyCode];
		},
	},
	{
		field: 'transaction_note',
		headerName: 'Nội dung giao dịch',
		minWidth: 160,
	},
	{
		field: 'payment_status',
		headerName: 'Trạng thái giao dịch',
		minWidth: 160,
		valueFormatter(params) {
			const { value }: { value: EPaymentStatus.Success | EPaymentStatus.Pending | EPaymentStatus.Failed } = params;

			return paymentStatusMap[value];
		},
	},
];

export const TransactionHistory = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [transactions, setTransactions] = useState<ITransaction[]>([]);
	const [transactionSummary, setTransactionSummary] = useState<ITransactionSummary>();
	const [isLoadingTransaction, setIsLoadingTransaction] = useState(true);
	const isApplyingFilterValue = useRef(searchParams.get('isFiltering') || false);
	const lastUpdatedAtRef = useRef('');
	const [paginationModel, setPaginationModel] = useState({
		pageSize: searchParams.get('limit') ? Number(searchParams.get('limit')) : DEFAULT_PAGE_SIZE,
		page: searchParams.get('page') ? (Number(searchParams.get('page')) - 1) : DEFAULT_PAGE,
	});
  const exportAndDownload = useDownloadHandler();
	const [sharedDataState] = useRecoilState(sharedDataAtom);
	const [pageState, setPageState] = useRecoilState(pageStateAtom);

	const fetchData = useCallback(
		async (params: ITransactionQuery) => {
			try {
				if (!sharedDataState.banks?.length) {
					return;
				}

				setIsLoadingTransaction(true);
        
				const [transactionResponse, transactionSummaryResponse] = await Promise.all([
					getTransactions(params),
					getTransactionsSummary(params),
				]);
				const transactionData = extractReponse<ITransaction[]>(transactionResponse).map(
					({ beneficiary_bank_code, ...rest }) => ({
						...rest,
						beneficiary_bank_code: sharedDataState.banks!.find(({ bank_code }) => bank_code === beneficiary_bank_code)!
							.bank_name_en,
					}),
				);
				const transactionSummaryData = extractReponse<ITransactionSummary[]>(transactionSummaryResponse);
				lastUpdatedAtRef.current = moment().format(DISPLAY_DATE_TIME_FORMAT).toString();

				setTransactions(transactionData);
				setTransactionSummary(transactionSummaryData?.[0]);
			} catch (exception) {
				setTransactions([]);
				setTransactionSummary({
					total_credit_amount: 0,
					total_debit_amount: 0,
					total_transaction: 0,
				});
				Logger.log(exception);
			} finally {
				setIsLoadingTransaction(false);
			}
		},
		[sharedDataState.banks.length],
	);

	const formik = useFormik({
		initialValues: {
			accountNumber: searchParams.get('account') || '',
			accountName: searchParams.get('name') || '',
			transactionNumber: searchParams.get('trx') || '',
			status: searchParams.get('status') || '',
			not: undefined,
			startDate: moment(searchParams.get('start') || undefined).subtract(1, 'year'),
			endDate: moment(searchParams.get('end') || undefined),
			statusQuickFilterValue: '',
      paymentUseCase: searchParams.get('case') || '',
      paymentNumbers: searchParams.get('payment_numbers') || '',
		},
		onSubmit: () => {
			isApplyingFilterValue.current = true;
			onChangePage({
				page: 0,
				pageSize: 10,
			});
		},
	});

	useEffect(() => {
		setPageState({
			...pageState,
			pageTitle: PAGE_TITLE,
		});
	}, []);

	useEffect(() => {
		fetchData({
			start: formik.values.startDate.format(DATE_FORMAT).toString(),
			end: formik.values.endDate.format(DATE_FORMAT).toString(),
			page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      ...(isApplyingFilterValue.current
				? mapValueToListApiPayload(formik.values)
				: {}),
		});
    setSearchParams(trimEmptyField({
      isFiltering: isApplyingFilterValue.current,
			start: formik.values.startDate.format(DATE_FORMAT).toString(),
			end: formik.values.endDate.format(DATE_FORMAT).toString(),
			page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      ...(isApplyingFilterValue.current
				? mapValueToListApiPayload(formik.values)
				: {}),
		}));
	}, [fetchData]);

	const onChangePage = (nextPaginationModel: typeof paginationModel) => {
		setPaginationModel(nextPaginationModel);
		const { startDate, endDate } = formik.values;
		fetchData({
			start: startDate.format(DATE_FORMAT).toString(),
			end: endDate.format(DATE_FORMAT).toString(),
      limit: nextPaginationModel.pageSize,
			page: nextPaginationModel.page + 1,
			...(isApplyingFilterValue.current
				? mapValueToListApiPayload(formik.values)
				: {}),
		});
    setSearchParams(trimEmptyField({
			start: startDate.format(DATE_FORMAT).toString(),
      limit: nextPaginationModel.pageSize,
			end: endDate.format(DATE_FORMAT).toString(),
			page: nextPaginationModel.page + 1,
			...(isApplyingFilterValue.current
				? mapValueToListApiPayload(formik.values)
				: {}),
      isFiltering: isApplyingFilterValue.current,
		}));
	};

  const handleDownloadReport = () => {
    const { startDate, endDate } = formik.values;

		exportAndDownload({
      reportType: 'transaction',
      reportConditions: {
        start_date: startDate.format(DATE_FORMAT).toString(),
        end_date: endDate.format(DATE_FORMAT).toString(),
        ...(isApplyingFilterValue.current
          ? mapValueToDownloadApiPayload(
            mapValueToListApiPayload(formik.values)
          )
          : {}),
      }
    })
  };

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>
						{PAGE_TITLE} | {APP_TITLE}
					</title>
				</Helmet>
			</HelmetProvider>
			<Card sx={{ padding: 2 }}>
				<Stack direction='row' spacing={2} sx={{ pb: 2, px: 2 }} alignItems='center'>
					<Typography>Tìm nhanh giao dịch</Typography>
					<Chip
						label='Thành công'
						color={formik.values.status === EPaymentStatus.Success ? 'primary' : 'default'}
						clickable
						onClick={() => {
							if (formik.values.status === EPaymentStatus.Success) {
								formik.setFieldValue('status', undefined);
								formik.setFieldValue('statusQuickFilterValue', undefined);
							} else {
								formik.setFieldValue('status', EPaymentStatus.Success);
								formik.setFieldValue('statusQuickFilterValue', 'success');
							}
							formik.submitForm();
						}}
					/>
					<Chip
						label='Khác'
						color={formik.values.statusQuickFilterValue === 'other' ? 'primary' : 'default'}
						clickable
						onClick={() => {
							if (formik.values.statusQuickFilterValue === 'other') {
                formik.setFieldValue('statusQuickFilterValue', undefined);
								formik.setFieldValue('not', undefined);
								formik.setFieldValue('status', undefined);
							} else {
								formik.setFieldValue('statusQuickFilterValue', 'other');
								formik.setFieldValue('status', undefined);
								formik.setFieldValue('not', EPaymentStatus.Success);
							}
							formik.submitForm();
						}}
					/>
				</Stack>
				<Box sx={{ display: 'grid', gap: 3, gridTemplateColumns: 'repeat(4, 1fr)' }}>
					<TextField size='small' label='Số tài khoản thanh toán' {...formik.getFieldProps('accountNumber')} />
					<TextField size='small' label='Đối tác' {...formik.getFieldProps('accountName')} />
					<TextField size='small' label='Mã giao dịch' {...formik.getFieldProps('transactionNumber')} />
          <TextField size='small' label='Mã thanh toán' {...formik.getFieldProps('paymentNumbers')} />
					<FormControl size='small' sx={{ minWidth: 150 }}>
						<InputLabel id='status-selection'>Trạng thái</InputLabel>
						<Select labelId='status-selection' autoWidth label='Trạng thái' {...formik.getFieldProps('status')}>
							<MenuItem value=''>
								<em>Tất cả</em>
							</MenuItem>
							{Object.entries(paymentStatusMap).map(([value, label]) => (
								<MenuItem key={value as string} value={value}>
									{label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
          <FormControl size='small' sx={{ minWidth: 150 }}>
						<InputLabel id='payment-usecase-selection'>Loại giao dịch</InputLabel>
						<Select labelId='payment-usecase-selection' autoWidth label='Loại giao dịch' {...formik.getFieldProps('paymentUseCase')}>
							<MenuItem value=''>
								<em>Tất cả</em>
							</MenuItem>
							{Object.entries(paymentUseCaseMap).map(([value, label]) => (
								<MenuItem key={value as string} value={value}>
									{label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								maxDate={moment()}
								format={DATE_FORMAT}
								label={TRADING_START_DATE}
								value={formik.values.startDate}
								onChange={(value) => formik.setFieldValue('startDate', value)}
								slotProps={{ textField: { size: 'small' } }}
							/>
						</LocalizationProvider>
					</FormControl>
					<FormControl>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								maxDate={moment()}
								format={DATE_FORMAT}
								label={TRADING_END_DATE}
								value={formik.values.endDate}
								onChange={(value) => formik.setFieldValue('endDate', value)}
								minDate={formik.values.startDate}
								slotProps={{ textField: { size: 'small' } }}
							/>
						</LocalizationProvider>
					</FormControl>
				</Box>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(1, 1fr)',
						gap: 1,
						mt: 1,
					}}>
					<Stack direction='row' spacing={2} sx={{ pt: 1, pb: 1 }} alignItems='right' justifyContent={'flex-end'}>
						<Button variant='outlined' sx={{ mr: 2 }} onClick={() => {
              formik.setValues({
                accountNumber: '',
                accountName: '',
                transactionNumber: '',
                status: '',
                not: undefined,
                startDate: moment().subtract(1, 'year'),
                endDate: moment(),
                statusQuickFilterValue: '',
                paymentUseCase: '',
                paymentNumbers: '',
              })
              isApplyingFilterValue.current = false;
              onChangePage({
                page: 0,
                pageSize: 10,
              });
            }}>
							{RESET}
						</Button>
						<Button variant='contained' onClick={formik.submitForm}>
							{SEARCH}
						</Button>
					</Stack>
				</Box>
			</Card>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					mb: 2,
					mt: 2,
				}}>
        <Box />
				<Box>
          <DownloadReport
						handleDownloadReport={handleDownloadReport}
					/>
					<ActionItem
						title={`${REFRESH}: ${LAST_UPDATED_AT} ${lastUpdatedAtRef.current}`}
						icon={Refresh}
						onClick={() => onChangePage(paginationModel)}
					/>
				</Box>
			</Box>
			<Grid container spacing={3}>
				<Grid item xs={12} md={12} lg={12}>
					<Card>
						<CustomDataGrid
							tableKey='TRANSACTION_HISTORY_TABLE'
							loading={isLoadingTransaction}
							rowCount={transactionSummary?.total_transaction || 0}
							rows={transactions}
							columns={columns}
							getRowId={(row: ITransaction) => row.transaction_number}
							paginationMode='server'
              pageSizeOptions={[10, 50, 100]}
							paginationModel={paginationModel}
							onPaginationModelChange={onChangePage}
							summaryRows={[
								{ key: TOTAL_DEBIT, value: formatDecimal(transactionSummary?.total_debit_amount || 0) },
								{ key: TOTAL_CREDIT, value: formatDecimal(transactionSummary?.total_credit_amount || 0) },
							]}
						/>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};
