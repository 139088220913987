import { atom } from "recoil";
import { AUTH_ATOM_KEY } from "../../utils/constants";

const authStateAtom = atom<{
  token: string;
  roles: string[];
  tenant_roles: string[];
  permissions: string[];
  isLoggedIn: boolean;
  isStatePersisted: boolean;
  username?: string;
}>({
  key: AUTH_ATOM_KEY,
  default: {
    token: '',
    permissions: [],
    roles: [],
    tenant_roles: [],
    isLoggedIn: false,
    isStatePersisted: false,
    username: ''
  },
  effects: [
    ({setSelf}) => {
      const savedData = localStorage.getItem(AUTH_ATOM_KEY);
      if (savedData) {
        setSelf(JSON.parse(savedData));
      }
    },
  ],
});

export default authStateAtom; 
