import { useRecoilState, useResetRecoilState } from 'recoil';
import authStateAtom from '../recoil/atoms/authStateAtom';
import { extractReponse } from '../utils/responseExtractor';
import { IAuthResponse } from '../types/IAuthResponse';
import { loginRequest, logoutRequest } from '../services/Auth';
import { AUTH_ATOM_KEY } from "../utils/constants";

export const useUserActions = () => {
  const [authState, setAuthState] = useRecoilState(authStateAtom);
  const resetAuthState = useResetRecoilState(authStateAtom);

  const login = async (username: string, password: string) => {
    const response = await loginRequest(username, password);
    const data = extractReponse<IAuthResponse[]>(response)[0];
    const authObj = {
      ...authState,
      isLoggedIn: true,
      isStatePersisted: true,
      token: data.access_token,
      permissions: data.client_permissions,
      roles: data.client_roles,
      tenant_roles: data.tenant_roles,
      username: username,
    }
    localStorage.setItem(AUTH_ATOM_KEY, JSON.stringify(authObj));
    setAuthState(authObj);
  }

  const logout = async () => {
    //TODO: best if the auth token can be invalidated on the server side, the token revoke endpoint returns 404
    //const response = await logoutRequest();
    // Remove the token from local storage if it is expired & set the default state
    localStorage.removeItem(AUTH_ATOM_KEY);
    resetAuthState();
  };

  return { login, logout };
};

export default useUserActions;