/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { APP_TITLE } from '../../../utils/constants';
import logo from '../../../logo.svg';
export const Logo = () => (
	<NavLink
		to='/'
		css={css`
			text-decoration: none;
			color: inherit;
		`}
	>
		<img
					css={css`
						height: 6vmin;
						pointer-events: none;
						@media (prefers-reduced-motion: no-preference) {
							animation: App-logo-spin infinite 15s linear;
						}
						@keyframes App-logo-spin {
							from {
								transform: rotate(0deg);
							}
							to {
								transform: rotate(360deg);
							}
						}
					`}
					src={logo}
					alt={APP_TITLE}
					title={APP_TITLE}
				/>
	</NavLink>
);
