export const START_DATE = 'Ngày bắt đầu gia';
export const END_DATE = 'Ngày kết thúc';
export const FILTER_TIME = 'Thời gian';
export const FILTER_TODAY = 'Hôm nay (mặc định)';
export const FILTER_YESTERDAY = 'Hôm qua';
export const FILTER_THIS_MONTH = 'Tháng này';
export const FILTER_LAST_MONTH = 'Tháng trước';
export const FILTER_CUSTOM = 'Tuỳ chỉnh';
export const OK = 'Ok';
export const CANCEL = 'Cancel';
export const DATE_FORMAT = 'YYYY-MM-DD';

export enum FilterEnum {
	Today = 'FILTER_TODAY',
	Yesterday = 'FILTER_YESTERDAY',
	LastMonth = 'FILTER_LAST_MONTH',
	ThisMonth = 'FILTER_THIS_MONTH',
	Custom = 'FILTER_CUSTOM',
}