import { EPaymentUsecase } from '../types/EPaymentUsecase';
import Request from './Request';

const PATH = '/api/v1/recon/bo';

export interface IGetReconHistoryListQuery {
  bank: string;
  type?: 'repayment' | 'disburse';
  trx?: string;
  start?: string;
  end?: string;
  status?: string;
  limit?: number;
  page?: number;
  sort?: string;
  not?: string;
};

export interface IReconcilation {
  reconcile_type: 'repayment' | 'disburse';
  transaction_number: string;
  account_number: string;
  counterpart_account_number: string;
  bank_code: string;
  counterpart_bank_code: string;
  transaction_amount: number;
  currency_code: string;
  bank_transaction_number: string;
  transaction_at: string;
  transaction_note: string;
  transaction_status: string;
  reconcile_status: string;
  reconciled_at: string;
  submission_id: string;
  submission_status: null | 'pending' | 'cancelled' | 'rejected' | 'finished' | 'incompleted';
  submission_ending_reason?: string;
  result_status: string;

  payment_use_case: string;
}

export interface IReconSummary {
  total_transaction: number;
  total_debit_amount: number;
  total_credit_amount: number;
};

export interface ISubmitAdjustmentParams {
  submission_type: 'adjustment' | 'enrollment';
  submission_note?: string;
  submission_attachment?: string;
  bank_code: string;
  reconcile_type: 'repayment' | 'disburse';
  transaction_number: string;
  adjust_to_status?: 'success' | 'failed';
}

export interface ISubmission {
  id: number;
  submission_type: string;
  submission_note: string;
  submission_attachment: string;
  submission_status: string;
  bank_code: string;
  reconcile_type: string;
  transaction_number: string;
  adjust_to_status: string;
  submission_ending_reason: string;
};


export interface IAdjustmentHistory {
  adjustment_group: 'reconcile' | 'submission';
  adjustment_name: 'recon_status' | 'trans_status' | 'submitted' | 'cancelled' | 'rejected' | 'approved' | 'finished' | 'incompleted';
  adjustment_value: string;
  created_by: string;
  created_at: string;
}

export const getReconHistoryList = async (params: IGetReconHistoryListQuery) => Request.get(`${PATH}/history/list`, { params });
export const getReconSummary = async (params: IGetReconHistoryListQuery) => Request.get(`${PATH}/history/summary`, { params });
export const submitAdjustment = async (payload: ISubmitAdjustmentParams) => Request.post(`${PATH}/adjust/submit`, payload);
export const getSubmissionById = async (id: string) => Request.get(`${PATH}/adjust/${id}`);
export const getAdjustmentHistory = async (transactionNumber: string) => Request.get(`${PATH}/adjust/history/${transactionNumber}`);
export const approveOrRejectTransactionAdjustment = async (submissionId: string, approvalStatus: 'approved' | 'rejected') => Request.post(`${PATH}/adjust/approve`, { submission_id: submissionId, approval_status: approvalStatus });

export default {
  getReconHistoryList,
  getReconSummary,
};