import {
	History,
  AccountBalance,
  AccountTree,
	RecentActors,
  People,
  Payment,
  Article,
  ListAlt,
} from '@mui/icons-material';

import { Account } from '../pages/Account';
import { Partner } from '../pages/Partner';
import { AccountIdentifier } from '../pages/AccountIdentifier';
import { TransactionHistory } from '../pages/TransactionHistory';
import { ReconHistory } from '../pages/ReconHistory';
import PaymentRequestRecon from '../pages/PaymentRequestRecon';
import PaymentRequestAccountingReport from '../pages/PaymentRequestAccountingReport';
import { Route } from '../types/Route';
import {
  NAV_ACCOUNT,
  NAV_PARTNER,
  NAV_ACCOUNT_IDENTIFIER,
  NAV_TRANSACTION_HISTORY,
  NAV_RECON_HISTORY,
  NAV_GALAXY_PAY_PAYMENT_REQUEST_LIST,
  NAV_GALAXY_PAY_PAYMENT_REQUEST_RECON,
  NAV_GALAXY_PAY_PAYMENT_REQUEST_ACCOUNTING_REPORT
} from '../utils/constants';
import PaymentRequestList from '../pages/PaymentRequestList';

const routes: Array<Route> = [
	{
    subHeader: 'Tổng quan',
		key: 'router-account',
		title: NAV_ACCOUNT,
		description: '',
		component: Account,
		path: '/',
		isEnabled: true,
		icon: AccountBalance,
	},
	{
		key: 'router-partner',
		title: NAV_PARTNER,
		description: '',
		component: Partner,
		path: '/partners',
		isEnabled: true,
		icon: People,
	},
	{
		key: 'router-account-identifier',
		title: NAV_ACCOUNT_IDENTIFIER,
		description: '',
		component: AccountIdentifier,
		path: '/virtual-account',
		isEnabled: true,
		icon: RecentActors,
	},
	{
		key: 'router-transaction-history',
		title: NAV_TRANSACTION_HISTORY,
		description: '',
		component: TransactionHistory,
		path: '/transaction-history',
		isEnabled: true,
		icon: History,
	},
	{
    subHeader: 'Ngân hàng',
    key: 'router-recon-history',
		title: NAV_RECON_HISTORY,
		description: '',
		component: ReconHistory,
		path: '/recon-history',
		isEnabled: true,
		icon: AccountTree,
  },
  {
    subHeader: 'GalaxyPay',
    key: 'router-galaxy-pay-payment-request-list',
    title: NAV_GALAXY_PAY_PAYMENT_REQUEST_LIST,
    description: '',
    component: PaymentRequestList,
    path: '/router-galaxy-pay-payment-request-list',
    isEnabled: true,
    icon: Payment,
  },
  {
    key: 'router-galaxy-pay-payment-request-reconciliation',
    title: NAV_GALAXY_PAY_PAYMENT_REQUEST_RECON,
    description: '',
    component: PaymentRequestRecon,
    path: '/router-galaxy-pay-payment-request-reconciliation',
    isEnabled: true,
    icon: AccountTree,
  },
  {
    key: 'router-galaxy-pay-payment-request-accounting-report',
    title: NAV_GALAXY_PAY_PAYMENT_REQUEST_ACCOUNTING_REPORT,
    description: '',
    component: PaymentRequestAccountingReport,
    path: '/router-galaxy-pay-payment-request-accounting-report',
    isEnabled: true,
    icon: Article,
  },
];

export default routes;
