import React, { useState } from 'react';
import { Box, List, Toolbar } from '@mui/material';
import { Logo } from './Logo';
import { Notifications, UserAccount } from '../Actions';
import { DropdownMenu } from './Menu/DropdownMenu';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { APP_TITLE } from '../../utils/constants';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {Routes} from '../Navigation/Routes'
import MuiDrawer from '@mui/material/Drawer';
import { useRecoilState, useResetRecoilState } from 'recoil';
import applicationStateAtom from '../../recoil/atoms/applicationStateAtom';
import pageStateAtom from '../../recoil/atoms/pageStateAtom';

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const drawerWidth: number = 240;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

export const Header = () => {
	
	const [pageState] = useRecoilState(pageStateAtom);
  const [applicationState, setApplicationState] = useRecoilState(applicationStateAtom);
  const { isDrawerOpened: open } = applicationState;

  const toggleDrawer = () => {
    setApplicationState({
      ...applicationState,
      isDrawerOpened: !open,
    })
  };
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};


	return (
		<>
			<AppBar sx={{ flex: 1 }} open={open}>
				<Toolbar
					sx={{
						pr: '24px', // keep right padding when drawer closed
					}}>
					<IconButton
						edge='start'
						color='inherit'
						aria-label='open drawer'
						onClick={toggleDrawer}
						sx={{
							marginRight: '36px',
							...(open && { display: 'none' }),
						}}>
						<MenuIcon />
					</IconButton>
					<Typography component='h1' variant='h6' color='inherit' noWrap sx={{ flexGrow: 1 }}>
						{pageState.pageTitle}
					</Typography>
					{/* <Notifications total={4} /> */}
					<UserAccount onClick={handleProfileMenuOpen} />
					<DropdownMenu isMenuOpen={Boolean(anchorEl)} handleMenuClose={handleMenuClose} anchorEl={anchorEl} />
				</Toolbar>
			</AppBar>
			<Drawer variant='permanent' open={open}>
				<Toolbar
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						background: 'transparent',
						px: [1],
					}}
        >
					<Box
						sx={{
							float: 'left',
						}}>
						<Logo />
					</Box>
					{/* <IconButton onClick={toggleDrawer}>
						<ChevronLeftIcon />
					</IconButton> */}
				</Toolbar>
				<Divider />
        <List>
          <Routes />
        </List>
			</Drawer>
		</>
	);
};
