import { atom } from "recoil";
import localStorageEffect from "../effects/localStorageSyncEffect";
import { APPLICATION_STATE_ATOM_KEY } from "../../utils/constants";

const applicationStateAtom = atom<{ isStatePersisted: boolean, isDrawerOpened: boolean, isLoading: boolean }>({
  key: APPLICATION_STATE_ATOM_KEY,
  default: {
    isStatePersisted: false,
    isDrawerOpened: false,
    isLoading: false,
  },
  effects: [
    localStorageEffect(APPLICATION_STATE_ATOM_KEY),
  ],
});

export default applicationStateAtom; 
