import { getBankList } from "../services/Core";
import { IBank } from "../types/IBank";
import { extractReponse } from "./responseExtractor";

export const loadBankListUntilEmpty = async () => {
  let bankList: IBank[] = [];

  const loadBankList = async () => {
    const responses = await Promise.all(
      Array.from({ length: 1 })
      .map((item, index) => getBankList({ page: index + 1, limit: 100, sort:"asc" }))
    );

    const bankListArray: Array<IBank[]> = responses.map((response) => extractReponse(response))

    bankList = bankList.concat(bankListArray.reduce((result, bankList) => {
      if (!bankList) {
        return result;
      }
      return result.concat(bankList);
    }, []));
  };

  await loadBankList();

  return bankList;
};
