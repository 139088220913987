import axios from "axios";
import { AUTH_ATOM_KEY } from "../utils/constants";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

instance.interceptors.request.use(
  (config) => {
    const savedData = localStorage.getItem(AUTH_ATOM_KEY);
    if(savedData) {
      const data = JSON.parse(savedData);
      if (data.token) {
        config.headers['Authorization'] = `Bearer ${data.token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.dispatchEvent(new Event('logout'));
      return {};
    }
    return Promise.reject(error);
  }
);

export default instance;

