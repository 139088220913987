import React, { useState, SyntheticEvent, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import {
	START_DATE,
	END_DATE,
	FILTER_TIME,
	FILTER_TODAY,
	FILTER_YESTERDAY,
	FILTER_THIS_MONTH,
	FILTER_LAST_MONTH,
	FILTER_CUSTOM,
	CANCEL,
	OK,
	DATE_FORMAT,
	FilterEnum
} from './constants';
import {TimeFilterProps} from './types';
import { DISPLAY_DATE_TIME_FORMAT } from '../../../utils/constants';
import { Stack } from '@mui/material';

export interface ITimeFilterRef { reset: () => void };

const CustomOptionDatePicker = ({
  enableDateRangeForCustomOption,
  isOpen,
  onClose,
  onConfirm,
}: {
  enableDateRangeForCustomOption: boolean,
  isOpen: boolean;
  onClose: any;
  onConfirm: any;
}) => {
  const [startDate, setStartDate] = useState<undefined | moment.Moment>();
  const [endDate, setEndDate] = useState<undefined | moment.Moment>();
  const oldValueRef = useRef({
    startDate,
    endDate,
  });

  const _onClose = () => {
    const revertStartAndEndDateChanges = () => {
      setStartDate(oldValueRef.current.startDate);
      setEndDate(oldValueRef.current.endDate);
    }
    revertStartAndEndDateChanges();
  
    onClose();
  };

  const _onConfirm = () => {
    onConfirm({
      startDate,
      endDate,
    })
  };

  const renderDialogContent = () => {
    if (enableDateRangeForCustomOption) {
      return (
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack direction='row'>
              <Box>
                <Typography>Chọn ngày bắt đầu</Typography>
                <DatePicker
                  format={DATE_FORMAT}
                  maxDate={moment()}
                  value={startDate}
                  onChange={(newValue: any) => {
                    oldValueRef.current.startDate = startDate;
                    setStartDate(newValue);
                  }}
                />
              </Box>
              <Box pl={2}>
                <Typography>Chọn ngày kết thúc</Typography>
                <DatePicker
                  format={DATE_FORMAT}
                  maxDate={moment()}
                  minDate={startDate}
                  disabled={!startDate}
                  value={endDate}
                  onChange={(newValue: any) => {
                    oldValueRef.current.endDate = endDate;
                    setEndDate(newValue);
                  }}
                />
              </Box>
            </Stack>
          </LocalizationProvider>
        </FormControl>
      );
    }
  
    return (
      <FormControl>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Typography>Chọn ngày</Typography>
          <DatePicker
            format={DATE_FORMAT}
            maxDate={moment()}
            value={startDate}
            onChange={(newValue: any) => setStartDate(newValue)}
          />
        </LocalizationProvider>
      </FormControl>
    );
  };

  return (
    <Dialog disableEscapeKeyDown open={isOpen} onClose={_onClose}>
      <DialogTitle>{FILTER_CUSTOM}</DialogTitle>
      <DialogContent>
        <Box component='form' sx={{ display: 'flex', flexWrap: 'wrap' }} gap={2}>
          {renderDialogContent()}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={_onClose}>{CANCEL}</Button>
        <Button onClick={_onConfirm}>{OK}</Button>
      </DialogActions>
    </Dialog>
  );
}

export const TimeFilter = forwardRef<ITimeFilterRef, TimeFilterProps>((props, ref) => {
	const [filterTimeOption, setFilterTimeOption] = useState(props.initialValue ?? FilterEnum.Today);
  const [customOptionLabel, setCustomOptionLabel] = useState(FILTER_CUSTOM);

  useImperativeHandle(ref, () => ({
    reset() {
      setFilterTimeOption(props.initialValue ?? FilterEnum.Today);
    },
  }));

	const handleChange = (event: SelectChangeEvent) => {
		setFilterTimeOption(event.target.value as FilterEnum);
		switch (event.target.value) {
			case FilterEnum.Today:
				props.handleFilterOptionChange({ option: event.target.value, selectedDate: moment() });
				break;
			case FilterEnum.Yesterday:
				props.handleFilterOptionChange({
					option: event.target.value,
					selectedDate: moment().subtract(1, 'days'),
				});
				break;
			case FilterEnum.ThisMonth:
				props.handleFilterOptionChange({
					option: event.target.value,
					selectedDate: moment().endOf('month'),
				});
				break;
			case FilterEnum.LastMonth:
				props.handleFilterOptionChange({
					option: event.target.value,
					selectedDate: moment().subtract(1, 'months').endOf('month'),
				});
				break;
			case FilterEnum.Custom:
				//Do nothing
				break;
			default:
				break;
		}
	};

	const handleOnClick = () => {
		setOpenCustomFilter(true);
	};

	const [openCustomFilter, setOpenCustomFilter] = useState(false);
	const handleClose = (event: SyntheticEvent<unknown>, reason?: string) => {
		if (reason !== 'backdropClick') {
			setOpenCustomFilter(false);
		}
	};
	const handleOk = ({ startDate, endDate }: { startDate: moment.Moment; endDate: moment.Moment }) => {
		setOpenCustomFilter(false);

    if (props.enableDateRangeForCustomOption) {
      setCustomOptionLabel(`${FILTER_CUSTOM}: ${startDate.format('DD/MM/YYYY').toString()} - ${endDate.format('DD/MM/YYYY').toString()}`)

      props.handleFilterOptionChange({
        option: FilterEnum.Custom,
        selectedDate: startDate,
        selectedEndDate: endDate,
      });
    } else {
      setCustomOptionLabel(`${FILTER_CUSTOM}: ${startDate.format('DD/MM/YYYY').toString()}`)

      props.handleFilterOptionChange({
        option: FilterEnum.Custom,
        selectedDate: startDate,
      });
    }
	};

	return (
		<>
			<FormControl size="small">
				<InputLabel id='time-filter-label'>{FILTER_TIME}</InputLabel>
				<Select
					labelId='time-filter-label'
					id='time-filter'
					value={filterTimeOption}
					label={FILTER_TIME}
					onChange={handleChange}>
					<MenuItem value={FilterEnum.Today}>{FILTER_TODAY}</MenuItem>
					<MenuItem value={FilterEnum.Yesterday}>{FILTER_YESTERDAY}</MenuItem>
					<MenuItem value={FilterEnum.ThisMonth}>{FILTER_THIS_MONTH}</MenuItem>
					<MenuItem value={FilterEnum.LastMonth}>{FILTER_LAST_MONTH}</MenuItem>
					<MenuItem value={FilterEnum.Custom} onClick={() => handleOnClick()}>
            {customOptionLabel}
					</MenuItem>
				</Select>
			</FormControl>
      <CustomOptionDatePicker
        isOpen={openCustomFilter}
        enableDateRangeForCustomOption={!!props.enableDateRangeForCustomOption}
        onClose={handleClose}
        onConfirm={handleOk}
      />
		</>
	);
});

TimeFilter.displayName = 'TimeFilter';
