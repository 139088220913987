/* eslint-disable react/display-name */
/** @jsxImportSource @emotion/react */
import {
	Box,
	Button,
	Card,
	Grid,
	Select,
	Stack,
	TextField,
	Typography,
	MenuItem,
	FormControl,
	InputLabel,
	Dialog,
	DialogTitle,
	DialogContent,
	List,
	ListItem,
	ListItemText,
	Snackbar,
	Alert,
} from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useRecoilState } from 'recoil';
import React, {
	useCallback,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
	forwardRef,
	useImperativeHandle,
	useEffect,
} from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Search } from '../../components/Filter/Search';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { Refresh } from '@mui/icons-material';
import {
	APP_TITLE,
	DATE_FORMAT,
	DISPLAY_DATE_TIME_FORMAT,
	CURRENCY,
	TOTAL_DEBIT,
	TOTAL_CREDIT,
	TRADING_START_DATE,
	TRADING_END_DATE,
	DOWNLOAD,
	RESET,
	SEARCH,
	FIND_TRANSACTION,
	RECON_FILTER_STATUSES,
  LAST_UPDATED_AT,
  REFRESH,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE
} from '../../utils/constants';
import Logger from '../../services/Logger';
import sharedDataAtom from '../../recoil/atoms/sharedDataAtom';
import { formatDecimal } from '../../utils/formatCurrency';
import { EPaymentStatus } from '../../types/EPaymentStatus';
import {
	IAdjustmentHistory,
	IGetReconHistoryListQuery,
	IReconSummary,
	IReconcilation,
	ISubmission,
	approveOrRejectTransactionAdjustment,
	getAdjustmentHistory,
	getReconHistoryList,
	getReconSummary,
	getSubmissionById,
	submitAdjustment,
} from '../../services/Recon';
import { extractReponse } from '../../utils/responseExtractor';
import { ActionItem } from '../../components/Actions/ActionItem';
import { EPaymentUsecase } from '../../types/EPaymentUsecase';
import applicationStateAtom from '../../recoil/atoms/applicationStateAtom';
import authStateAtom from '../../recoil/atoms/authStateAtom';
import { PAGE_TITLE } from './constants';
import pageStateAtom from '../../recoil/atoms/pageStateAtom';
import { CurrencyCode } from '../../types/Currency';
import { CustomDataGrid } from '../../components/DataGrid/CustomDataGrid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useDownloadHandler } from '../../hooks/useDownloadHandler';
import { DownloadReport } from '../../components/Actions/DownloadReport';

const paymentStatusMap = {
	[EPaymentStatus.Success]: 'Thành công',
	[EPaymentStatus.Failed]: 'Thất bại',
	[EPaymentStatus.Pending]: 'Đang xử lý',
	[EPaymentStatus.Settling]: 'Chờ xử lý',
	[EPaymentStatus.Expired]: 'Hết hạn',
};

const paymentUseCaseMap = {
	[EPaymentUsecase.P01]: 'Thu',
	[EPaymentUsecase.P02]: 'Chi',
	[EPaymentUsecase.P03]: 'Nội bộ',
};

const mapValueToListRequestPayload = (values: any) => {
  const { status, transaction, bank ,startDate, endDate } = values;

  const generateStatusValue = () => {
    switch (status) {
      case 'matched':

        return {
          status: '00',
        };

      case 'unmatched':
        return {
          not: '00'
        };

      case 'notReconYet':
        return {
          status: 'NULL',
        };

      default:
        return {};
    }
  };

  return {
    bank,
    start: startDate.format(DATE_FORMAT).toString(),
    end: endDate.format(DATE_FORMAT).toString(),
    trx: transaction,
    ...generateStatusValue(),
  };
};

const mapListRequestPayloadToDownloadPayload = (values: any) => {
  const {
    bank,
    start,
    end,
    trx,
    status,
    not,
  } = values;

  return {
    bank_code: bank,
    start_date: start,
    end_date: end,
    transaction_number: trx,
    reconcile_status: status,
    non_reconcile_status: not,
  }
}

const ReconDetailsDialogs = forwardRef<
	{ toggle: (value: boolean) => void },
	{ reconcilationItem: IReconcilation | undefined }
>(({ reconcilationItem }, ref) => {
	const [isOpen, setIsOpen] = useState(false);
	const [snackBarType, setSnackBarType] = useState<'success' | 'failed' | null>(null);
	const [dialogType, setDialogType] = useState<'adjustment' | 'adjustment_transaction' | 'details'>('details');
	const [submission, setSubmission] = useState<ISubmission | null>(null);
	const [adjustmentHistories, setAdjustmentHistories] = useState<IAdjustmentHistory[]>([]);
	const [applicationState, setApplicationState] = useRecoilState(applicationStateAtom);
	const [pageState, setPageState] = useRecoilState(pageStateAtom);
	const [sharedData] = useRecoilState(sharedDataAtom);
	const [authState] = useRecoilState(authStateAtom);

	const columns = useMemo(() => {
		return [
			{
				field: 'created_at',
				headerName: 'Thời gian',
				minWidth: 200,
				valueFormatter(params) {
					return moment(params.value).format(DISPLAY_DATE_TIME_FORMAT).toString();
				},
			},
			{
				field: 'content',
				headerName: 'Nội dung',
				minWidth: 400,
				renderCell(params) {
					const { row }: { row: IAdjustmentHistory } = params;
					const adjustmentNameLabelMap: {
						[key in IAdjustmentHistory['adjustment_name']]: string;
					} = {
						approved: 'đã được duyệt',
						cancelled: 'bị hủy',
						finished: 'hoàn thành',
						incompleted: 'chưa hoàn thành',
						recon_status: 'được yêu cầu thay đổi trạng thái',
						trans_status: 'được yêu cầu thay đổi trạng thái',
						rejected: 'bị từ chối',
						submitted: 'đang đợi duyệt',
					};

					const noun = row.adjustment_group === 'reconcile' ? 'Đối soát' : 'Điều chỉnh';
					const restOfTheSentence = adjustmentNameLabelMap[row.adjustment_name];
					return `${noun}: ${restOfTheSentence}`;
				},
			},
			{
				field: 'created_by',
				headerName: 'Thực hiện',
				minWidth: 200,
			},
		] as GridColDef[];
	}, []);
	const adjustmentForm = useFormik({
		initialValues: {
			status: EPaymentStatus.Success,
			note: undefined,
			attachment: undefined,
		},
		async onSubmit(values) {
			try {
				setApplicationState({
					...applicationState,
					isLoading: true,
				});

				const response = await submitAdjustment({
					bank_code: reconcilationItem!.bank_code,
					reconcile_type: reconcilationItem!.reconcile_type,
					submission_type: 'adjustment',
					transaction_number: reconcilationItem!.transaction_number,
					adjust_to_status: values.status as 'success' | 'failed',
					submission_attachment: values.attachment,
					submission_note: values.note,
				});
				setIsOpen(false);
				setSnackBarType('success');
			} catch (exception) {
				Logger.log(exception);
				setSnackBarType('failed');
			} finally {
				setApplicationState({
					...applicationState,
					isLoading: false,
				});
			}
		},
	});

	const adjustmentTransactionForm = useFormik({
		initialValues: {
			note: '',
			attachment: '',
		},
		async onSubmit(values) {
			try {
				setApplicationState({
					...applicationState,
					isLoading: true,
				});

				const response = await submitAdjustment({
					bank_code: reconcilationItem!.bank_code,
					reconcile_type: reconcilationItem!.reconcile_type,
					submission_type: 'enrollment',
					submission_attachment: values.attachment,
					transaction_number: reconcilationItem!.transaction_number,
				});
				setIsOpen(false);
				setSnackBarType('success');
			} catch (exception) {
				Logger.log(exception);
				setSnackBarType('failed');
			} finally {
				setApplicationState({
					...applicationState,
					isLoading: false,
				});
			}
		},
	});

	useEffect(() => {
		setPageState({
			...pageState,
			pageTitle: PAGE_TITLE,
		});
	}, []);

	useLayoutEffect(() => {
		if (!isOpen) {
			setDialogType('details');
		}
	}, [isOpen]);

	useEffect(() => {
		const loadSubmission = async () => {
			try {
				const response = await getSubmissionById(reconcilationItem!.submission_id);
				const data = extractReponse<ISubmission[]>(response)?.[0] || null;

				setSubmission(data);
			} catch (exception) {
				Logger.log(exception);
			}
		};

		const loadAdjustmentHistories = async () => {
			try {
				const response = await getAdjustmentHistory(reconcilationItem!.transaction_number);
				const data = extractReponse<IAdjustmentHistory[]>(response);
				setAdjustmentHistories(data);
			} catch (exception) {
				Logger.log(exception);
			}
		};

		if (reconcilationItem?.submission_id) {
			loadSubmission();
		}

		if (reconcilationItem?.transaction_number) {
			loadAdjustmentHistories();
		}
	}, [reconcilationItem?.submission_id, reconcilationItem?.transaction_number]);

	useImperativeHandle(ref, () => ({
		toggle: (value: boolean | undefined) => {
			if (value === undefined) {
				setIsOpen(!isOpen);
			} else {
				setIsOpen(value);
			}
		},
	}));

	if (!reconcilationItem) {
		return null;
	}

	const handleSnackBarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackBarType(null);
	};

	const approveOrRejectSubmission = async (status: 'approved' | 'rejected') => {
		try {
			setApplicationState({
				...applicationState,
				isLoading: true,
			});

			const response = await approveOrRejectTransactionAdjustment(reconcilationItem.submission_id, status);
			setIsOpen(false);
			setSnackBarType('success');
		} catch (exception) {
			Logger.log(exception);
			setSnackBarType('failed');
		} finally {
			setApplicationState({
				...applicationState,
				isLoading: false,
			});
		}
	};

	const DetailDialogContent = () => {
		const mapReason = () => {
			if (!reconcilationItem.reconcile_status) {
				return 'Giao dịch chưa được đối soát';
			}

			if (reconcilationItem.reconcile_status !== '00') {
				return 'Trạng thái giao dịch ngày phát sinh giao dịch và ngày đối soát không trùng khớp';
			}
		};

		return (
			<>
				<Box pb={2}>
					<Typography variant='h6' pb={1}>
						Lý do
					</Typography>
					<Typography variant='body2'>{mapReason()}</Typography>
				</Box>
				<Box pb={2}>
					<Typography variant='h6' pb={1}>
						Trạng thái giao dịch
					</Typography>
					<List sx={{ pt: 0 }}>
						<ListItem alignItems='flex-start'>
							<ListItemText
								primary={paymentStatusMap[reconcilationItem.transaction_status as keyof typeof paymentStatusMap] || ''}
								secondary='Ngày phát sinh'
							/>
						</ListItem>
						<ListItem alignItems='flex-start'>
							<ListItemText
								primary={paymentStatusMap[reconcilationItem.result_status as keyof typeof paymentStatusMap] || '-'}
								secondary='Ngày đối soát'
							/>
						</ListItem>
					</List>
				</Box>
				{!!(submission && reconcilationItem.submission_id) && (
					<Box pb={6}>
						<Typography variant='h6' pb={1}>
							Thông tin bổ sung
						</Typography>
						<List sx={{ pt: 0 }}>
							<ListItem alignItems='flex-start'>
								<ListItemText primary={submission.submission_note || '-'} secondary='Ghi chú' />
							</ListItem>
							<ListItem alignItems='flex-start'>
								<ListItemText primary={submission.submission_attachment || '-'} secondary='Tài liệu' />
							</ListItem>
						</List>
					</Box>
				)}
				{!!reconcilationItem.submission_status && (
					<DataGrid
						rows={adjustmentHistories}
						columns={columns}
						pageSizeOptions={[100]}
						autoHeight
						getRowId={(row: IAdjustmentHistory) => {
							return JSON.stringify(row);
						}}
					/>
				)}
				<Box pt={6}>
					<Stack direction='column' spacing={1}>
						{(!reconcilationItem.submission_status ||
							['cancelled', 'rejected'].includes(reconcilationItem.submission_status)) &&
							authState.tenant_roles.includes('maker') && (
								<>
									<Button
										disabled={!reconcilationItem.transaction_status || !reconcilationItem.result_status}
										variant='contained'
										onClick={() => setDialogType('adjustment')}>
										Điều chỉnh trạng thái giao dịch
									</Button>
									<Button
										variant='outlined'
										onClick={() => setDialogType('adjustment_transaction')}
										disabled={!!reconcilationItem.transaction_status}>
										Tạo giao dịch điều chỉnh
									</Button>
								</>
							)}
						{authState.tenant_roles.includes('checker') && reconcilationItem.submission_status === 'pending' && (
							<>
								<Button variant='contained' onClick={() => approveOrRejectSubmission('approved')}>
									Duyệt
								</Button>
								<Button variant='outlined' onClick={() => approveOrRejectSubmission('rejected')}>
									Từ chối
								</Button>
							</>
						)}
						<Button variant='text' onClick={() => setIsOpen(false)}>
							Quay lại
						</Button>
					</Stack>
				</Box>
			</>
		);
	};

	const AdjustmentDialogContent = () => {
		return (
			<>
				<Box pb={2}>
					<Typography variant='h6' pb={1}>
						Loại điều chỉnh
					</Typography>
					<Typography variant='body2'>Điều chỉnh trạng thái giao dịch</Typography>
				</Box>
				<Box pb={2}>
					<Typography variant='h6' pb={1}>
						Thông tin điều chỉnh
					</Typography>
					<Select fullWidth label='Chọn trạng thái điều chỉnh' {...adjustmentForm.getFieldProps('status')}>
						{Object.entries(
							(({ success, failed }) => ({
								success,
								failed,
							}))(paymentStatusMap),
						).map(([value, label]) => (
							<MenuItem value={value} key={value}>
								{label}
							</MenuItem>
						))}
					</Select>
				</Box>
				<Box pb={2}>
					<Typography variant='h6' pb={1}>
						Ghi chú
					</Typography>
					<TextField fullWidth placeholder='Nhập ghi chú' {...adjustmentForm.getFieldProps('note')} />
				</Box>
				<Box pb={6}>
					<Typography variant='h6' pb={1}>
						Tài liệu
					</Typography>
					<TextField fullWidth placeholder='Đường dẫn đến document' {...adjustmentForm.getFieldProps('attachment')} />
				</Box>
				<Box>
					<Stack direction='column' spacing={1}>
						<Button variant='contained' onClick={adjustmentForm.submitForm}>
							Gửi yêu cầu
						</Button>
						<Button variant='text' onClick={() => setIsOpen(false)}>
							Hủy
						</Button>
					</Stack>
				</Box>
			</>
		);
	};

	const AdjustmentTransactionDialogContent = () => {
		return (
			<>
				<Box pb={2}>
					<Typography variant='h6' pb={1}>
						Loại điều chỉnh
					</Typography>
					<Typography variant='body2'>Tạo giao dịch điều chỉnh</Typography>
				</Box>
				<Box pb={2}>
					<Typography variant='h6'>Thông tin giao dịch</Typography>
					<List sx={{ pt: 0 }}>
						<ListItem alignItems='flex-start'>
							<ListItemText
								primary={paymentUseCaseMap[reconcilationItem.payment_use_case as keyof typeof paymentUseCaseMap]}
								secondary='Loại thông tin'
							/>
						</ListItem>
						<ListItem alignItems='flex-start'>
							<ListItemText primary={formatDecimal(reconcilationItem.transaction_amount)} secondary='Số tiền' />
						</ListItem>
						<ListItem alignItems='flex-start'>
							<ListItemText primary='VND' secondary='Đơn vị' />
						</ListItem>
						<ListItem alignItems='flex-start'>
							<ListItemText primary={reconcilationItem.transaction_note} secondary='Nội dung' />
						</ListItem>
					</List>
				</Box>
				<Box pb={2}>
					<Typography variant='h6'>Thông tin thụ hưởng</Typography>
					<List sx={{ pt: 0 }}>
						<ListItem alignItems='flex-start'>
							<ListItemText primary={reconcilationItem.counterpart_account_number} secondary='Số tài khoản' />
						</ListItem>
						<ListItem alignItems='flex-start'>
							<ListItemText
								primary={
									sharedData.banks.find(({ bank_code }) => bank_code === reconcilationItem.counterpart_bank_code)
										?.bank_name_en
								}
								secondary='Ngân hàng'
							/>
						</ListItem>
						<ListItem alignItems='flex-start'>
							<ListItemText primary='' secondary='Chủ tài khoản' />
						</ListItem>
					</List>
				</Box>
				<Box pb={2}>
					<Typography variant='h6'>Thông tin tài khoản</Typography>
					<List sx={{ pt: 0 }}>
						<ListItem alignItems='flex-start'>
							<ListItemText primary={reconcilationItem.account_number} secondary='Số tài khoản' />
						</ListItem>
						<ListItem alignItems='flex-start'>
							<ListItemText
								primary={
									sharedData.banks.find(({ bank_code }) => bank_code === reconcilationItem.bank_code)?.bank_name_en
								}
								secondary='Ngân hàng'
							/>
						</ListItem>
						<ListItem alignItems='flex-start'>
							<ListItemText primary='' secondary='Chủ tài khoản' />
						</ListItem>
					</List>
				</Box>
				<Box pb={2}>
					<Typography variant='h6' pb={1}>
						Ghi chú
					</Typography>
					<TextField fullWidth placeholder='Nhập ghi chú' {...adjustmentTransactionForm.getFieldProps('note')} />
				</Box>
				<Box pb={6}>
					<Typography variant='h6' pb={1}>
						Tài liệu
					</Typography>
					<TextField
						fullWidth
						placeholder='Đường dẫn đến document'
						{...adjustmentTransactionForm.getFieldProps('attachment')}
					/>
				</Box>
				<Box>
					<Stack direction='column' spacing={1}>
						<Button variant='contained' onClick={adjustmentTransactionForm.submitForm}>
							Gửi yêu cầu
						</Button>
						<Button variant='text' onClick={() => setIsOpen(false)}>
							Hủy
						</Button>
					</Stack>
				</Box>
			</>
		);
	};

	const DIALOG_CONTENT_MAP: {
		[key in typeof dialogType]: any;
	} = {
		details: DetailDialogContent,
		adjustment: AdjustmentDialogContent,
		adjustment_transaction: AdjustmentTransactionDialogContent,
	};

	const DIALOG_TITLE_MAP: {
		[key in typeof dialogType]: any;
	} = {
		details: 'Chi tiết',
		adjustment: 'Thông tin điều chỉnh',
		adjustment_transaction: 'Thông tin điều chỉnh',
	};

	return (
		<>
			<Dialog open={isOpen} maxWidth='md' fullWidth>
				<DialogTitle variant='h5' textAlign='center'>
					{DIALOG_TITLE_MAP[dialogType]}
				</DialogTitle>
				<DialogContent>{DIALOG_CONTENT_MAP[dialogType]()}</DialogContent>
			</Dialog>
			<Snackbar open={!!snackBarType} autoHideDuration={6000} onClose={handleSnackBarClose}>
				{snackBarType === 'success' ? (
					<Alert onClose={handleSnackBarClose} severity='success' sx={{ width: '100%' }}>
						Thành công
					</Alert>
				) : (
					<Alert onClose={handleSnackBarClose} severity='error' sx={{ width: '100%' }}>
						Thất bại
					</Alert>
				)}
			</Snackbar>
		</>
	);
});

export const ReconHistory = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [reconcilations, setReconcilations] = useState<IReconcilation[]>([]);
	const [reconSummary, setReconSummary] = useState<IReconSummary>();
	const [isLoadingTransaction, setIsLoadingTransaction] = useState(true);
	const [sharedData] = useRecoilState(sharedDataAtom);
	const isApplyingFilterValue = useRef(false);
	const [selectedRecon, setSelectedRecon] = useState<IReconcilation>();
	const adjustmentDialogRef = useRef({ toggle: () => {} });
	const [paginationModel, setPaginationModel] = useState({
		pageSize: searchParams.get('limit') ? Number(searchParams.get('limit')) : DEFAULT_PAGE_SIZE,
		page: searchParams.get('page') ? Number(searchParams.get('page')) - 1 : DEFAULT_PAGE,
	});
	const lastUpdatedAtRef = useRef('');
	const [sharedDataState] = useRecoilState(sharedDataAtom);
  const exportAndDownload = useDownloadHandler();

	const columns = useMemo(() => {
		return [
			{
				field: 'transaction_at',
				headerName: 'Ngày phát sinh giao dịch',
				minWidth: 200,
				type: 'dateTime',
				valueFormatter(params) {
					return moment(params.value).format(DISPLAY_DATE_TIME_FORMAT).toString();
				},
			},
			{
				field: 'reconciled_at',
				headerName: 'Ngày đối soát',
				minWidth: 200,
				type: 'dateTime',
				valueFormatter(params) {
          if (!params.value) {
            return '';
          }

					return moment(params.value).format(DISPLAY_DATE_TIME_FORMAT).toString();
				},
			},
			{
				field: 'transaction_number',
				headerName: 'Mã giao dịch hệ thống',
				minWidth: 300,
			},
			{
				field: 'bank_transaction_number',
				headerName: 'Mã đối soát',
				minWidth: 250,
			},
			{
				field: 'reconcile_type',
				headerName: 'Loại giao dịch',
				width: 100,
				valueFormatter(params) {
					const valueMap: {
						[key in IReconcilation['reconcile_type']]: string;
					} = {
						repayment: 'Thu hộ',
						disburse: 'Chi hộ',
					};

					return valueMap[params.value as IReconcilation['reconcile_type']];
				},
			},
			{
				field: 'bank_code',
				headerName: 'Ngân hàng thụ hưởng',
				minWidth: 200,
				valueFormatter(params) {
					const { value } = params;

					return sharedData.banks.find(({ bank_code }) => bank_code === value)?.bank_name_en;
				},
			},
			{
				field: 'account_number',
				headerName: 'Tài khoản thanh toán',
				minWidth: 200,
			},
			{
				field: 'counterpart_bank_code',
				headerName: 'Ngân hàng đối ứng',
				minWidth: 200,
				valueFormatter(params) {
					const { value } = params;

					return sharedData.banks.find(({ bank_code }) => bank_code === value)?.bank_name_en;
				},
			},
			{
				field: 'counterpart_account_number',
				headerName: 'Tài khoản đối ứng',
				minWidth: 200,
			},
			{
				field: 'NA',
				headerName: 'Ghi nợ',
				minWidth: 160,
				align: 'right',
				headerAlign: 'right',
				valueGetter(params) {
					const { row }: { row: IReconcilation } = params;

					if (row.reconcile_type === 'disburse') {
						return row.transaction_amount;
					}

					return '';
				},
				valueFormatter(params) {
					return params.value && formatDecimal(params.value);
				},
			},
			{
				field: 'NA1',
				headerName: 'Ghi có',
				minWidth: 160,
				align: 'right',
				headerAlign: 'right',
				valueGetter(params) {
					const { row }: { row: IReconcilation } = params;

					if (row.reconcile_type === 'repayment') {
						return row.transaction_amount;
					}

					return '';
				},
				valueFormatter(params) {
					return params.value && formatDecimal(params.value);
				},
			},
			{
				field: 'currency_code',
				headerName: CURRENCY,
				align: 'center',
				headerAlign: 'center',
				valueFormatter(params) {
					return CurrencyCode[params.value as keyof typeof CurrencyCode];
				},
			},
			{
				field: 'transaction_note',
				headerName: 'Nội dung giao dịch',
				minWidth: 160,
			},
			{
				field: 'transaction_status',
				headerName: 'Trạng thái giao dịch',
				minWidth: 160,
				valueFormatter(params) {
					const {
						value,
					}: {
						value: EPaymentStatus.Success | EPaymentStatus.Pending | EPaymentStatus.Failed;
					} = params;

					return paymentStatusMap[value];
				},
			},
			{
				field: 'reconcile_status',
				headerName: 'Trạng thái đối soát',
				minWidth: 160,
				valueFormatter(params) {
          if (params.value === null) {
            return 'Chờ đối soát'
          };

					if (params.value === '00') {
						return 'Khớp';
					}

					return 'Không khớp';
				},
			},
			{
				field: 'submission_status',
				headerName: 'Trạng thái điều chỉnh',
				minWidth: 160,
				valueFormatter(params) {
					const { value }: { value: IReconcilation['submission_status'] } = params;

					switch (true) {
						case value === 'pending':
							return 'Chờ duyệt';

						case value === 'rejected':
							return 'Từ chối';

						case value === 'finished':
							return 'Đã duyệt';

            case value === 'incompleted':
              return 'Chưa hoàn thành';

						default:
							return '';
					}
				},
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: '',
				minWidth: 80,
				renderCell(params) {
					const { row }: { row: IReconcilation } = params;

					return (
						<Button
							onClick={() => {
								setSelectedRecon(row);
								adjustmentDialogRef.current.toggle();
							}}
							size='small'
							variant='outlined'>
							Chi tiết
						</Button>
					);
				},
			},
		] as GridColDef[];
	}, [sharedData.banks]);

	const fetchData = useCallback(
		async ({ start, end, status, not, page, bank, trx, limit }: IGetReconHistoryListQuery) => {
			try {
				if (!sharedDataState.banks.length) {
					return;
				}

				setIsLoadingTransaction(true);

				const response = await getReconHistoryList({
					bank,
					start,
					end,
					page,
          limit,
					status,
					not,
          trx,
				});
				const summaryResponse = await getReconSummary({
					bank,
					start,
					end,
					page,
          limit,
					status,
					not,
          trx,
				});
				const data = extractReponse<IReconcilation[]>(response) || [];
				const summaryData = extractReponse<IReconSummary[]>(summaryResponse)?.[0];
				lastUpdatedAtRef.current = moment().format(DISPLAY_DATE_TIME_FORMAT).toString();

				setReconcilations(data);
				setReconSummary(summaryData);
			} catch (exception) {
				Logger.log(exception);
			} finally {
				setIsLoadingTransaction(false);
			}
		},
		[sharedDataState.banks.length],
	);

	const formik = useFormik<{
		status: keyof typeof RECON_FILTER_STATUSES | undefined | null;
		startDate: moment.Moment;
		endDate: moment.Moment;
		bank: string;
    transaction: string;
	}>({
		initialValues: {
			status: undefined,
			startDate: moment().subtract(1, 'year'),
			endDate: moment(),
			bank: '302',
      transaction: '',
		},
		onSubmit: () => {
			isApplyingFilterValue.current = true;
			onChangePage({
				page: 0,
				pageSize: 10,
			});
		},
		onReset: () => {
			isApplyingFilterValue.current = false;
			onChangePage({
				page: 0,
				pageSize: 10,
			});
		},
	});

	useLayoutEffect(() => {
		fetchData({
			bank: formik.values.bank,
			start: formik.values.startDate.format(DATE_FORMAT).toString(),
			end: formik.values.endDate.format(DATE_FORMAT).toString(),
			page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
		});
    setSearchParams({
			page: `${paginationModel.page + 1}`,
      limit: `${paginationModel.pageSize}`,
		});
	}, [fetchData]);

	const onChangePage = (nextPaginationModel: typeof paginationModel) => {
		setPaginationModel(nextPaginationModel);

		fetchData({
			page: nextPaginationModel.page + 1,
      limit: paginationModel.pageSize,
      bank: formik.values.bank,
			start: formik.values.startDate.format(DATE_FORMAT).toString(),
			end: formik.values.endDate.format(DATE_FORMAT).toString(),
      ...(isApplyingFilterValue.current ? mapValueToListRequestPayload(formik.values) : {}) as { },
		});
		setSearchParams({
			page: `${nextPaginationModel.page + 1}`,
      limit: `${nextPaginationModel.pageSize}`,
		});
	};

	const exportToExcel = async () => {
    

    exportAndDownload({
      reportType: 'reconciliation',
      reportConditions: {
        ...(isApplyingFilterValue.current ? mapListRequestPayloadToDownloadPayload(mapValueToListRequestPayload(formik.values)) : {}),
      }
    });
  };

	  
	const handleSearch = (value: string) => {
		formik.setFieldValue('transaction', value);
	};

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>
						{PAGE_TITLE} | {APP_TITLE}
					</title>
				</Helmet>
			</HelmetProvider>
			<Card sx={{ padding: 2, paddingTop: 3 }}>
				<Box sx={{ display: 'grid', gap: 3, gridTemplateColumns: 'repeat(4, 1fr)' }}>
					<FormControl size='small' sx={{ minWidth: 150 }}>
						<InputLabel id='bank-selection'>Ngân hàng</InputLabel>
						<Select labelId='bank-selection' autoWidth label='Ngân hàng' {...formik.getFieldProps('bank')}>
							<MenuItem value=''>
								<em>Tất cả</em>
							</MenuItem>
							{sharedDataState.banks
                .filter(({ is_active: isActive }) => isActive)
                .map(({ bank_code: value, bank_name_en: label }) => (
								<MenuItem key={value as string} value={value}>
									{label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl size='small' sx={{ minWidth: 150 }}>
						<InputLabel id='status-selection'>Trạng thái</InputLabel>
						<Select labelId='status-selection' autoWidth label='Trạng thái' {...formik.getFieldProps('status')}>
							<MenuItem value=''>
								<em>Tất cả</em>
							</MenuItem>
							{Object.entries(RECON_FILTER_STATUSES).map(([value, label]) => (
								<MenuItem key={value as string} value={value}>
									{label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								maxDate={moment()}
								format={DATE_FORMAT}
								label={TRADING_START_DATE}
								value={formik.values.startDate}
								onChange={(value) => formik.setFieldValue('startDate', value)}
								slotProps={{ textField: { size: 'small' } }}
							/>
						</LocalizationProvider>
					</FormControl>
					<FormControl>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								maxDate={moment()}
								format={DATE_FORMAT}
								label={TRADING_END_DATE}
								value={formik.values.endDate}
								onChange={(value) => formik.setFieldValue('endDate', value)}
								minDate={formik.values.startDate}
								slotProps={{ textField: { size: 'small' } }}
							/>
						</LocalizationProvider>
					</FormControl>
				</Box>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(1, 1fr)',
						gap: 1,
						mt: 1,
					}}>
					<Stack direction='row' spacing={2} sx={{ pt: 1, pb: 1 }} alignItems='right' justifyContent={'flex-end'}>
						<Button variant='outlined' sx={{ mr: 2 }} onClick={formik.handleReset}>
							{RESET}
						</Button>
						<Button variant='contained' onClick={formik.submitForm}>
							{SEARCH}
						</Button>
					</Stack>
				</Box>
			</Card>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					mb: 2,
					mt: 2,
				}}>
				<Search placeholder={FIND_TRANSACTION} handleTextInput={handleSearch} />
				<Box>
          <DownloadReport
						handleDownloadReport={exportToExcel}
					/>
					<ActionItem
						title={`${REFRESH}: ${LAST_UPDATED_AT} ${lastUpdatedAtRef.current}`}
						icon={Refresh}
						onClick={() => onChangePage(paginationModel)}
					/>
				</Box>
			</Box>
			<Grid container spacing={3}>
				<Grid item xs={12} md={12} lg={12}>
					<Card>
						<CustomDataGrid
							tableKey='RECON_HISTORY_TABLE'
							loading={isLoadingTransaction}
							rowCount={reconSummary?.total_transaction || 0}
							rows={reconcilations}
							columns={columns}
							getRowId={(row: IReconcilation) => row.transaction_number}
							paginationMode='server'
							paginationModel={paginationModel}
              pageSizeOptions={[10, 50, 100]}
							onPaginationModelChange={onChangePage}
							summaryRows={[
								{ key: TOTAL_DEBIT, value: formatDecimal(reconSummary?.total_debit_amount || 0) },
								{ key: TOTAL_CREDIT, value: formatDecimal(reconSummary?.total_credit_amount || 0) },
							]}
						/>
					</Card>
				</Grid>
			</Grid>
			<ReconDetailsDialogs ref={adjustmentDialogRef} reconcilationItem={selectedRecon} />
		</>
	);
};

export default ReconHistory;
