import Request from './Request';

const BANKING_PATH = '/api/v1/common/bo/banks';
const PARTNER_PATH = 'TBD';

export const getBankList = async (params: { page: number; limit?: number; sort?: 'asc' | 'desc' }) => {
  return Request.get(BANKING_PATH, { params });
};

export const getPartnerList = async (params: { page: number; limit?: number; sort?: 'asc' | 'desc' }) => {
  return Request.get(PARTNER_PATH, { params });
};