import { AtomEffect, DefaultValue } from "recoil";

const localStorageEffect: <Type>(key: string) => AtomEffect<Type> = (
  key: string
) => ({ setSelf, onSet }) => {
  const savedValue = localStorage.getItem(key);

  if (savedValue != null) {
    setSelf({
      ...JSON.parse(savedValue),
      isStatePersisted: true,
      isLoading: false,
    });
  } else {
    setSelf((defaultValue: any) => {
      return {
        ...defaultValue,
        isStatePersisted: true,
      };
    });
  }

  onSet((newValue) => {
    if (newValue instanceof DefaultValue) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(newValue));
    }
  });
};

export default localStorageEffect;
