/** @jsxImportSource @emotion/react */
import { Box, Button, Card, Grid, Select, Stack, MenuItem, FormControl, InputLabel, Input, TextField, Link } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { GridColDef } from '@mui/x-data-grid';
import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
	APP_TITLE,
	DATE_FORMAT,
	RESET,
	SEARCH,
	REFRESH,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE,
  DISPLAY_DATE_FORMAT,
} from '../../utils/constants';
import { PAGE_TITLE } from './constants';
import Logger from '../../services/Logger';
import { formatDecimal } from '../../utils/formatCurrency';
import { Refresh } from '@mui/icons-material';
import { DownloadReport } from '../../components/Actions/DownloadReport';
import pageStateAtom from '../../recoil/atoms/pageStateAtom';
import { ITimeFilterRef } from '../../components/Filter/TimeFilter';
import { ActionItem } from '../../components/Actions/ActionItem';
import moment from 'moment';
import { CustomDataGrid } from '../../components/DataGrid/CustomDataGrid';
import { extractReponse } from '../../utils/responseExtractor';
import { useDownloadHandler } from '../../hooks/useDownloadHandler';
import { trimEmptyField } from '../../utils/trimEmptyField';
import { IPaymentLinkRecon, IPaymentLinkReconQuery, IPaymentLinkSettlement, IPaymentLinkSummary,ISettlementSummary,PaymentMethodTyped, getPaymentLinkRecon, getPaymentLinkReconSummary, getPaymentLinkSettlementSummary, getPaymentLinkSettlements } from '../../services/Payment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const INITIAL_VALUE: {
  startDate: moment.Moment;
  endDate: moment.Moment;
} = {
  startDate: moment().startOf('month'),
  endDate: moment(),
};

const mapValueToRequestPayload = (values: typeof INITIAL_VALUE) => {
  const { startDate, endDate } = values;
  const payload: {
    status?: IPaymentLinkReconQuery['status'] | '';
    start?: IPaymentLinkReconQuery['start'] | '';
    end?: IPaymentLinkReconQuery['end'] | '';
    method?: IPaymentLinkReconQuery['method'] | '',
    number?: IPaymentLinkReconQuery['number'] | '',
    ref?: IPaymentLinkReconQuery['ref'] | '',
  } = {
    start: startDate.format(DATE_FORMAT).toString(),
    end: endDate.format(DATE_FORMAT).toString(),
  };

  return trimEmptyField<IPaymentLinkReconQuery>(payload);
};

const mapListRequestPayloadToDownloadRequestPayload = (values: any) => {
  const {
    acc,
    bank,
    status,
    cif,
    type,
    start,
    end,
    date,
    sme,
  } = values;

  const ifUserChoiceDateRange = (_start: string, _end: string) => _start && _end;
  const today = moment().format(DATE_FORMAT).toString();
  
  return {
    account_number: acc,
    bank_code: bank,
    aggregated_status: status,
    cif_type: type,
    cif_number: cif,
    sme_number: sme,
    start_date: start || undefined,
    end_date: end || undefined,
    occurring_date: date || (ifUserChoiceDateRange(start, end) ? undefined : today),
  };
}

export const PaymentRequestAccountingReport = () => {	
	const [pageState, setPageState] = useRecoilState(pageStateAtom);
	const [searchParams, setSearchParams] = useSearchParams();
  const [paymentLinks, setPaymentLinks] = useState<IPaymentLinkSettlement[]>([])
  const [summary, setSummary] = useState<ISettlementSummary | null>(null)
	const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const timeFilterRef = useRef<ITimeFilterRef>({ reset() {}, });
	const [paginationModel, setPaginationModel] = useState({
		pageSize: searchParams.get('limit') ? Number(searchParams.get('limit')) : DEFAULT_PAGE_SIZE,
		page: searchParams.get('page') ? Number(searchParams.get('page')) - 1 : DEFAULT_PAGE,
	});
  const isApplyingFilterValue = useRef(searchParams.get('isApplyingFilterValue') || false);
  const exportAndDownload = useDownloadHandler();

  const columns: GridColDef<IPaymentLinkSettlement & { index: number }>[] = useMemo(() => [
    {
      field: 'reconciled_date',
      headerName: 'Ngày chốt đối soát',
      flex: 1,
      minWidth: 200,
      valueFormatter(params) {
        if (params.value) {
          return moment(params.value).format(DISPLAY_DATE_FORMAT).toString();
        }
  
        return '';
      },
    },
    {
      field: 'domestic_reconciled_amount',
      headerName: 'Tổng tiền giao dịch nội địa đã đối soát',
      flex: 1,
      minWidth: 250,
      valueFormatter(params) {
        if (params.value) {
          return formatDecimal(params.value);
        }
  
        return '';
      },
    },
    {
      field: 'international_reconciled_amount',
      headerName: 'Tổng tiền giao dịch quốc tế đã đối soát',
      flex: 1,
      minWidth: 250,
      valueFormatter(params) {
        if (params.value) {
          return formatDecimal(params.value);
        }
  
        return '';
      },
    },
    {
      field: 'domestic_settled_amount',
      headerName: 'Tổng tiền giao dịch nội địa đã hạch toán',
      flex: 1,
      minWidth: 250,
      valueFormatter(params) {
        if (params.value) {
          return formatDecimal(params.value);
        }
  
        return '';
      },
    },
    {
      field: 'international_settled_amount',
      headerName: 'Tổng tiền giao dịch quốc tế đã hạch toán ',
      flex: 1,
      minWidth: 250,
      valueFormatter(params) {
        if (params.value) {
          return formatDecimal(params.value);
        }
  
        return '';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      minWidth: 80,
      renderCell(params) {
        const { row }: { row: IPaymentLinkSettlement } = params;
        const startDate = moment(row.reconciled_date).subtract(1, 'day').format(DATE_FORMAT).toString();
        const endDate = moment(row.reconciled_date).format(DATE_FORMAT).toString();

        return (
          <Button
            onClick={() => {
              navigate(`/router-galaxy-pay-payment-request-list?page=1&isApplyingFilterValue=true&startDate=${startDate}&endDate=${endDate}&paymentStatus=settled`)
            }}
            size='small'
            variant='outlined'>
            Chi tiết
          </Button>
        );
      },
    },
  ], []);

	const fetchData = useCallback(
		async (params: IPaymentLinkReconQuery) => {
			try {
				setIsLoading(true);

				const response = await getPaymentLinkSettlements(params);
        const summaryResponse = await getPaymentLinkSettlementSummary(params);
        const paymentLinks = extractReponse<IPaymentLinkSettlement[]>(response);
        const summary = extractReponse<ISettlementSummary[]>(summaryResponse)

        setPaymentLinks(paymentLinks || []);
        setSummary(summary?.[0]);
			} catch (exception) {
				Logger.log(exception);
			} finally {
				setIsLoading(false);
			}
		},
		[],
	);

	const formik = useFormik<typeof INITIAL_VALUE>({
		initialValues: {
      startDate: searchParams.get('startDate') ? moment(searchParams.get('startDate')) : INITIAL_VALUE.startDate,
      endDate: searchParams.get('endDate') ? moment(searchParams.get('endDate')) : INITIAL_VALUE.endDate,
    },
		onSubmit: () => {
      isApplyingFilterValue.current = true;
			onChangePage({
				page: 0,
				pageSize: 10,
			});
		},
	});

	useEffect(() => {
		setPageState({
			...pageState,
			pageTitle: PAGE_TITLE,
		});
		fetchData({
			page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      ...(isApplyingFilterValue.current ? mapValueToRequestPayload(formik.values) : {
        start: INITIAL_VALUE.startDate.format(DATE_FORMAT).toString(),
        end: INITIAL_VALUE.endDate.format(DATE_FORMAT).toString(),
      }),
		});
    setSearchParams(trimEmptyField({
			page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      isApplyingFilterValue: isApplyingFilterValue.current,
      ...(isApplyingFilterValue.current ? formik.values : {
        startDate: INITIAL_VALUE.startDate.format(DATE_FORMAT).toString(),
        endDate: INITIAL_VALUE.endDate.format(DATE_FORMAT).toString(),
      })
		}));
	}, [fetchData]);

	const onChangePage = (nextPaginationModel: typeof paginationModel) => {
		setPaginationModel(nextPaginationModel);

		fetchData({
			page: nextPaginationModel.page + 1,
      limit: nextPaginationModel.pageSize,
      ...(isApplyingFilterValue.current ? mapValueToRequestPayload(formik.values) : {
        start: INITIAL_VALUE.startDate.format(DATE_FORMAT).toString(),
        end: INITIAL_VALUE.endDate.format(DATE_FORMAT).toString(),
      }),
		});
		setSearchParams(trimEmptyField({
			page: nextPaginationModel.page + 1,
      limit: nextPaginationModel.pageSize,
      isApplyingFilterValue: isApplyingFilterValue.current,
      ...(isApplyingFilterValue.current ? formik.values : {
        startDate: INITIAL_VALUE.startDate.format(DATE_FORMAT).toString(),
        endDate: INITIAL_VALUE.endDate.format(DATE_FORMAT).toString(),
      })
		}));
	};

	const handleDownloadReport = () => {
    alert('API chưa support');
    // exportAndDownload({
    //   reportConditions: (isApplyingFilterValue.current ? mapListRequestPayloadToDownloadRequestPayload(mapValueToRequestPayload(formik.values)) : {
    //     occurring_date: moment().format(DATE_FORMAT).toString(),
    //   }),
    //   reportType: 'va_balance_history',
    // });
	};

	const handleFilterReset = () => {
		formik.setValues(INITIAL_VALUE);
    isApplyingFilterValue.current = false;
    timeFilterRef.current.reset();
    onChangePage({
      page: 0,
      pageSize: 10,
    });
	}

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>
						{PAGE_TITLE} | {APP_TITLE}
					</title>
				</Helmet>
			</HelmetProvider>
			<Card sx={{ padding: 2, paddingTop: 3 }}>
				<Box sx={{ display: 'grid', gap: 3, gridTemplateColumns: 'repeat(4, 1fr)' }}>
          <FormControl>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								maxDate={moment()}
								format={DATE_FORMAT}
								label="Thời gian bắt đầu"
								value={formik.values.startDate}
								onChange={(value) => formik.setFieldValue('startDate', value)}
								slotProps={{ textField: { size: 'small' } }}
							/>
						</LocalizationProvider>
					</FormControl>
          <FormControl>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								maxDate={moment()}
                minDate={formik.values.startDate}
								format={DATE_FORMAT}
								label="Thời gian kết thúc"
								value={formik.values.endDate}
								onChange={(value) => formik.setFieldValue('endDate', value)}
								slotProps={{ textField: { size: 'small' } }}
							/>
						</LocalizationProvider>
					</FormControl>
					{/* <TimeFilter
            ref={timeFilterRef}
            enableDateRangeForCustomOption
            handleFilterOptionChange={() => {}}
            initialValue={formik.values.selectedTimeFilter as FilterEnum.Today}
          /> */}
				</Box>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(1, 1fr)',
						gap: 1,
						mt: 1,
					}}>
					<Stack direction='row' spacing={2} sx={{ pt: 1, pb: 1 }} alignItems='right' justifyContent={'flex-end'}>
						<Button variant='outlined' sx={{ mr: 2 }} onClick={handleFilterReset}>
							{RESET}
						</Button>
						<Button variant='contained' onClick={formik.submitForm}>
							{SEARCH}
						</Button>
					</Stack>
				</Box>
			</Card>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					mb: 2,
					mt: 2,
				}}>
        <Box />
				<Box>
					<DownloadReport
						handleDownloadReport={handleDownloadReport}
					/>
					<ActionItem title={REFRESH} icon={Refresh} onClick={() => onChangePage(paginationModel)} />
				</Box>
			</Box>
			<Grid container spacing={3}>
				<Grid item xs={12} md={12} lg={12}>
					<Card>
						<CustomDataGrid
							tableKey='PAYMENT_LINK_TABLE'
							loading={isLoading}
							rowCount={summary?.total_settlement || 0}
							rows={paymentLinks}
							columns={columns}
							getRowId={(row: IPaymentLinkSettlement) => JSON.stringify(row)}
							paginationModel={paginationModel}
							onPaginationModelChange={onChangePage}
              pageSizeOptions={[10, 50, 100]}
							summaryRows={[]}
						/>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};

export default PaymentRequestAccountingReport;
