import Request from './Request';

const PATH = '/api/v1/core/bo/payment-link';
const RECON_PATH = '/api/v1/recon/bo/payment-link';

export const PaymentMethodTyped = ['bank_transfer',
 'domestic_card',
 'credit_card',
 'qrpay',
 'ewallet_momo',
 'ewallet_zalopay',
 'ewallet_viettel',
 'ewallet_galaxypay'
] as const;

export interface IPaymentLinkReconQuery {
  link?: string;
  number?: string;
  ref?: string;
  method?: string;
  status?: string;
  start?: string;
  end?: string;
  limit?: number;
  page?: number;
  sort?: string;
}

export interface IPaymentLinkSettlementQuery {
  start?: string;
  end?: string;
  limit?: number;
  page?: number;
  sort?: string;
};

export interface IPaymentLinkSettlement {
  reconciled_date: string;
  domestic_reconciled_amount: number;
  international_reconciled_amount: number;
  settled_date: string;
  domestic_settled_amount: number;
  international_settled_amount: number;
  currency_code: string;
  domestic_settle_result: 'NULL' | 'match' | 'unmatch';
  international_settle_result: 'NULL' | 'match' | 'unmatch';
  settle_progress_state: 'none' | 'in_progress' | 'finished' | 'incomplete';
  settle_failure_reason?: string;
}

export interface IPaymentLinkRecon {
  provider_code: 'GAL';
  payment_link_number: string;
  payment_number: string;
  provider_reference_id: string;
  payment_method: 'bank_transfer' | 'domestic_card' | 'credit_card' | 'qrpay' | 'ewallet_momo' | 'ewallet_zalopay' | 'ewallet_viettel' | 'ewallet_galaxypay';
  transaction_amount: number;
  currency_code: '704';
  payment_source_provider: string;
  payment_source_account: string;
  payment_source_type: 'Account' | 'e-wallet' | 'card';
  transaction_at: string;
  transaction_status: 'success' | 'failed';
  result_status: 'NULL' | 'success' | 'failed';
  reconcile_status: 'NULL' | '00' | '01' | '02' | '03' | '04';
  reconciled_at: string;
}


export interface IPaymentLinkQuery {
  number?: string;
  link?: string;
  type?: 'single' | 'multiple';
  expired?: boolean;
  method?: typeof PaymentMethodTyped[number];
  state?: 'unpaid' | 'paid' | 'partial_pay' | 'extra_paid';
  status?: 'created' | 'success' | 'reconciled' | 'settled' | 'closed';
  start?: string;
  end?: string;
  limit?: number;
  page?: number;
  sort?: 'asc' | 'desc';
  vendor?: string;
}

export interface IPaymentLink {
  payment_link_number: string;
  payment_link_amount: number;
  paid_amount: number;
  linking_account_number: string;
  currency_code: string;
  payment_link_status: 'unpaid' | 'paid' | 'partial_pay' | 'extra_paid';
  payment_link_type: 'single' | 'multiple';
  payment_link_description: string;
  expired_at: string;
  created_at: string;
  request_details: IRequestDetail[];
}

export interface IRequestDetail {
  payment_number: string;
  payment_method: 'bank_transfer' | 'domestic_card' | 'credit_card' | 'qrpay' | 'ewallet_momo' | 'ewallet_zalopay' | 'ewallet_viettel' | 'ewallet_galaxypay';
  total_amount: number;
  base_fee_amount: number;
  markup_fee_amount: number;
  net_amount: number;
  currency_code: string;
  provider_reference_id?: string;
  payment_request_status: 'created' | 'success' | 'reconciled' | 'settled' | 'closed';
  created_at: string;
  paid_at?: string;
  reconciled_at?: string;
  settlement_details: ISettlementDetail[];
}

interface ISettlementDetail {
  fee_type: 'net_trx'| 'base_fee' | 'markup_fee';
  payment_at: string;
  payment_number: string;
}

export interface IPaymentLinkSummary {
  total_transaction: number;
  total_base_fee_amount: number;
  total_markup_fee_amount: number;
  total_net_amount: number;
  total_paid_amount: number;
  total_payment_amount: number;
};

export interface ISettlementSummary {
  total_settlement: number;
}


export interface IPaymentLinkReconSummary {
  total_transaction: number;
  total_amount: number;
};


export const getPaymentLinkHistories = (query: IPaymentLinkQuery) => Request.get(`${PATH}/history`, { params: query });
export const getPaymentLinkRecon = (query: IPaymentLinkReconQuery) => Request.get(`${RECON_PATH}/list`, { params: query });
export const getPaymentLinkReconSummary = (query: IPaymentLinkReconQuery) => Request.get(`${RECON_PATH}/summary`, { params: query });
export const getPaymentLinkSummary = (query: IPaymentLinkQuery) => Request.get(`${PATH}/summary`, { params: query });
export const getPaymentLinkSettlements = (query: IPaymentLinkSettlementQuery) => Request.get(`${PATH}/settle/history`, { params: query });
export const getPaymentLinkSettlementSummary = (query: IPaymentLinkSettlementQuery) => Request.get(`${PATH}/settle/summary`, { params: query });

