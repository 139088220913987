/** @jsxImportSource @emotion/react */
import { Box, Button, Card, Grid, Select, Stack, MenuItem, FormControl, InputLabel, Input, TextField, Link } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { GridColDef } from '@mui/x-data-grid';
import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
	APP_TITLE,
	DATE_FORMAT,
	DISPLAY_DATE_TIME_FORMAT,
	RESET,
	SEARCH,
	REFRESH,
	SELECT_BANK,
	SELECT_STATUS,
	PARTNER_NAME,
	PARTNER_CODE,
	PARTNER_BANK_CODE,
	INITIAL_BALANCE,
	DEBIT,
	CREDIT,
	SYSTEM_END_BALANCE,
	END_OF_PERIOD_BALANCE,
	BALANCE_COMPARISON,
	TOTAL_DEBIT,
	TOTAL_CREDIT,
	TOTAL_SYSTEM_END_BALANCE,
	TOTAL_INITIAL_BALANCE,
	TOTAL_END_OF_PERIOD_BALANCE,
	END_OF_PERIOD_BALANCE_TOOLTIP,
	BALANCE_COMPARISON_TOOLTIP,
	CURRENCY,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
} from '../../utils/constants';
import { PAGE_TITLE, FIND_ACCOUNT_IDENTIFIER } from './constants';
import Logger from '../../services/Logger';
import sharedDataAtom from '../../recoil/atoms/sharedDataAtom';
import { formatDecimal } from '../../utils/formatCurrency';
import { Refresh } from '@mui/icons-material';
import { DownloadReport } from '../../components/Actions/DownloadReport';
import pageStateAtom from '../../recoil/atoms/pageStateAtom';
import { ITimeFilterRef, TimeFilter } from '../../components/Filter/TimeFilter';
import { ActionItem } from '../../components/Actions/ActionItem';
import moment from 'moment';
import { CustomDataGrid } from '../../components/DataGrid/CustomDataGrid';
import { Search } from '../../components/Filter/Search';
import { CustomColumnHeader } from '../../components/DataGrid/CustomColumnHeader';
import { DownloadReportResultType } from '../../components/Actions/DownloadReport/types';
import { CurrencyCode } from '../../types/Currency';
import { useLocation } from 'react-router-dom';
import { FilterEnum } from '../../components/Filter/TimeFilter/constants';
import { IAccountBalanceHistory, IAccountBalanceSummary, IGetAccountBalanceHistoryQueryParameters, getAccountBalanceHistory, getAccountBalanceSummary } from '../../services/Balance';
import { extractReponse } from '../../utils/responseExtractor';
import { useDownloadHandler } from '../../hooks/useDownloadHandler';
import { trimEmptyField } from '../../utils/trimEmptyField';

export const RECON_FILTER_STATUSES = {
  matched: 'Khớp',
  unmatched: 'Không khớp',
};

const columns: GridColDef[] = [
	{
		field: 'account_name',
		headerName: 'Tên tài khoản',
		flex: 1,
    minWidth: 300,
	},
  {
		field: 'sme_number',
		headerName: 'Mã đối tác',
		flex: 1,
    minWidth: 200,
    valueFormatter(params) {
      return params.value || '-';
    },
	},
  {
		field: 'cif_number',
		headerName: 'Mã khách hàng',
		flex: 1,
    minWidth: 200,
	},
  {
		field: 'business_id',
		headerName: 'Business ID',
		flex: 1,
    minWidth: 300,
	},
  {
		field: 'bank_code',
		headerName: PARTNER_BANK_CODE,
		flex: 1,
    minWidth: 150,
	},
  {
		field: 'account_number',
		headerName: 'Số tài khoản',
		flex: 1,
    minWidth: 200,
    renderCell(params) {
      const { row }: { row: IAccountBalanceHistory } = params;

      return (
        <Link href={`/transaction-history?isFiltering=true&account=${row.account_number}`}>{row.account_number}</Link>
      );
    },
	},
  {
		field: 'cif_type',
		headerName: 'Phân loại',
    minWidth: 100,
		valueGetter(params) {
      const { row }: { row: IAccountBalanceHistory } = params;

			if (row.cif_type === '2') {
        return 'Tổ chức';
      }

      if (row.cif_type === '1') {
        return 'Cá nhân';
      }
		},
	},
	{
		field: 'previous_current_amount',
		headerName: INITIAL_BALANCE,
		flex: 1,
    align: 'right',
    headerAlign: 'right',
    minWidth: 150,
    valueFormatter(params) {
			return params.value && formatDecimal(params.value);
		},
	},
	{
		field: 'total_debit_amount',
		headerName: DEBIT,
		flex: 1,
		align: 'right',
		headerAlign: 'right',
    minWidth: 150,
    valueFormatter(params) {
			return params.value && formatDecimal(params.value);
		},
	},
	{
		field: 'total_credit_amount',
		headerName: CREDIT,
		flex: 1,
		align: 'right',
		headerAlign: 'right',
    minWidth: 150,
    valueFormatter(params) {
			return params.value && formatDecimal(params.value);
		},
	},
	{
		field: 'balance_current_amount',
		headerName: SYSTEM_END_BALANCE,
		flex: 1,
		align: 'right',
		headerAlign: 'right',
    minWidth: 200,
    valueFormatter(params) {
			return params.value && formatDecimal(params.value);
		},
	},
	{
		field: 'aggregated_amount',
		headerName: END_OF_PERIOD_BALANCE,
		align: 'right',
		headerAlign: 'right',
		renderHeader: () => CustomColumnHeader(END_OF_PERIOD_BALANCE, END_OF_PERIOD_BALANCE_TOOLTIP),
		flex: 1,
		minWidth: 200,
		valueFormatter(params) {
			return params.value && formatDecimal(params.value);
		},
	},
	{
		field: 'currency_code',
		headerName: CURRENCY,
		align: 'center',
		headerAlign: 'center',
		valueFormatter(params) {
			return CurrencyCode[params.value as keyof typeof CurrencyCode];
		},
	},
	{
		field: 'aggregated_status',
		headerName: BALANCE_COMPARISON,
		renderHeader: () => CustomColumnHeader(BALANCE_COMPARISON, BALANCE_COMPARISON_TOOLTIP),
		flex: 1,
    minWidth: 150,
		valueGetter(params) {
      const { row }: { row: IAccountBalanceHistory } = params;

      if (row.aggregated_status === 'matched') {
        return 'Khớp';
      }

      if (row.aggregated_status === 'unmatched') {
        return 'Không khớp';
      }

      if (row.occurring_date) {
        return 'Chờ đối chiếu';
      }

      return '-';
		},
	},
];

const mapValueToRequestPayload = (values: any) => {
  const {
    bank,
    partner,
    status,
    selectedDate,
    selectedEndDate,
    accountType,
    cifNumber,
    smeNumber
  } = values;

  const mapDate = (_start: string, _end: string) => {
    if (_start && _end) {
      return {
        start: _start,
        end: _end,
      }
    }

    if (_start) {
      return {
        date: _start,
      }
    }
  };

  return {
    acc: partner,
    bank,
    status,
    cif: cifNumber || undefined,
    type: accountType,
    sme: smeNumber,
    ...mapDate(selectedDate?.format(DATE_FORMAT)?.toString(), selectedEndDate?.format(DATE_FORMAT)?.toString()),
  }
};

const mapListRequestPayloadToDownloadRequestPayload = (values: any) => {
  const {
    acc,
    bank,
    status,
    cif,
    type,
    start,
    end,
    date,
    sme,
  } = values;

  const ifUserChoiceDateRange = (_start: string, _end: string) => _start && _end;
  const today = moment().format(DATE_FORMAT).toString();
  
  return {
    account_number: acc,
    bank_code: bank,
    aggregated_status: status,
    cif_type: type,
    cif_number: cif,
    sme_number: sme,
    start_date: start || undefined,
    end_date: end || undefined,
    occurring_date: date || (ifUserChoiceDateRange(start, end) ? undefined : today),
  };
}

export const AccountIdentifier = () => {	
	const [pageState, setPageState] = useRecoilState(pageStateAtom);
	const [searchParams, setSearchParams] = useSearchParams();
	const [partners, setPartners] = useState<IAccountBalanceHistory[]>([]);
	const [partnerSummary, setPartnerSummary] = useState<IAccountBalanceSummary>();
	const [isLoading, setIsLoading] = useState(true);
  const timeFilterRef = useRef<ITimeFilterRef>({ reset() {}, });
	const [paginationModel, setPaginationModel] = useState({
		pageSize: searchParams.get('limit') ?
      Number(searchParams.get('limit')) :
      DEFAULT_PAGE_SIZE,
		page: searchParams
      .get('page') ?
        Number(searchParams.get('page')) - 1 :
        DEFAULT_PAGE,
	});
  const isApplyingFilterValue = useRef(searchParams.get('isApplyingFilterValue') || false);
	const lastUpdatedRef = useRef('');
	const [sharedDataState] = useRecoilState(sharedDataAtom);
  const exportAndDownload = useDownloadHandler();

	const fetchData = useCallback(
		async (params: IGetAccountBalanceHistoryQueryParameters) => {
			try {
				if (!sharedDataState.banks.length || !sharedDataState.partners.length) {
					return;
				}

				setIsLoading(true);

				const response = await getAccountBalanceHistory({
          ...params,
        });

				const summaryResponse = await getAccountBalanceSummary({
          ...params,
        });

        const partners = extractReponse<IAccountBalanceHistory[]>(response)?.map(({ bank_code, ...rest }) => {
          return {
            ...rest,
            bank_code: sharedDataState.banks.find(bank => bank.bank_code === bank_code)!.bank_name_en,
          }
        });
        const summary = extractReponse<IAccountBalanceSummary[]>(summaryResponse)?.[0];


				lastUpdatedRef.current = moment().format(DISPLAY_DATE_TIME_FORMAT).toString();

				setPartners(partners || []);
				setPartnerSummary(summary);
			} catch (exception) {
				Logger.log(exception);
			} finally {
				setIsLoading(false);
			}
		},
		[sharedDataState.banks.length],
	);

	const formik = useFormik<{
		status: 'matched' | 'unmatched' | 'missed' | '';
    selectedDate: moment.Moment;
    selectedEndDate: undefined | moment.Moment;
		bank: string;
		partner: string;
    accountType: '1' | '2' | '',
    selectedTimeFilter: undefined | string;
    cifNumber: string;
    smeNumber: string;
	}>({
		initialValues: {
      cifNumber: searchParams.get('cif') || '',
			status: searchParams.get('status') as 'matched' | 'unmatched' | 'missed' || '',
			bank: searchParams.get('bank') || '',
			partner: searchParams.get('acc') || '',
      selectedDate: moment(searchParams.get('date') || searchParams.get('start') || undefined),
      selectedEndDate: searchParams.get('end') ? moment(searchParams.get('end')) : undefined,
      selectedTimeFilter: searchParams.get('selectedTimeFilter') || FilterEnum.Today,
      accountType: searchParams.get('type') as '1' | '2' || '',
      smeNumber: searchParams.get('sme') || '',
		},
		onSubmit: () => {
      isApplyingFilterValue.current = true;
			onChangePage({
				page: 0,
				pageSize: 10,
			});
		},
	});

	useEffect(() => {
		setPageState({
			...pageState,
			pageTitle: PAGE_TITLE,
		});
		fetchData({
			page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      ...(isApplyingFilterValue.current ? mapValueToRequestPayload(formik.values) : {
        date: moment().format(DATE_FORMAT).toString(),
      }),
		});
    setSearchParams(trimEmptyField({
			page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      isApplyingFilterValue: isApplyingFilterValue.current,
      selectedTimeFilter: formik.values.selectedTimeFilter,
      ...(isApplyingFilterValue.current ? mapValueToRequestPayload(formik.values) : {
        date: moment().format(DATE_FORMAT).toString(),
      }),
		}));
	}, [fetchData]);

	const onChangePage = (nextPaginationModel: typeof paginationModel) => {
		setPaginationModel(nextPaginationModel);

		fetchData({
			page: nextPaginationModel.page + 1,
      limit: nextPaginationModel.pageSize,
      ...(isApplyingFilterValue.current ? mapValueToRequestPayload(formik.values) : {
        date: moment().format(DATE_FORMAT).toString(),
      }),
		});
		setSearchParams(trimEmptyField({
			page: nextPaginationModel.page + 1,
      limit: nextPaginationModel.pageSize,
      isApplyingFilterValue: isApplyingFilterValue.current,
      selectedTimeFilter: formik.values.selectedTimeFilter,
      ...(isApplyingFilterValue.current ? mapValueToRequestPayload(formik.values) : {
        date: moment().format(DATE_FORMAT).toString(),
      }),
		}));
	};

	const handleFilterOptionChange = ({ option, selectedDate, selectedEndDate }: { option: string; selectedDate: moment.Moment; selectedEndDate?: moment.Moment; }) => {
    formik.setFieldValue('selectedTimeFilter', option);

    if (option !== FilterEnum.Custom) {
      formik.setFieldValue('selectedDate', selectedDate);
      formik.setFieldValue('selectedEndDate', undefined);
    } else {
      formik.setFieldValue('selectedDate', selectedDate);
      formik.setFieldValue('selectedEndDate', selectedEndDate);
    }
	};

	const handleDownloadReport = () => {
    exportAndDownload({
      reportConditions: (isApplyingFilterValue.current ? mapListRequestPayloadToDownloadRequestPayload(mapValueToRequestPayload(formik.values)) : {
        occurring_date: moment().format(DATE_FORMAT).toString(),
      }),
      reportType: 'va_balance_history',
    });
	};

	const handleFilterReset = () => {
		formik.setValues({
      cifNumber: '',
      status: '',
      bank: '',
      partner: '',
      selectedDate: moment(),
      selectedEndDate: undefined,
      selectedTimeFilter: FilterEnum.Today,
      accountType: '',
      smeNumber: '',
    })
    isApplyingFilterValue.current = false;
    timeFilterRef.current.reset();
    onChangePage({
      page: 0,
      pageSize: 10,
    });
	}

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>
						{PAGE_TITLE} | {APP_TITLE}
					</title>
				</Helmet>
			</HelmetProvider>
			<Card sx={{ padding: 2, paddingTop: 3 }}>
				<Box sx={{ display: 'grid', gap: 3, gridTemplateColumns: 'repeat(4, 1fr)' }}>
					<FormControl size='small' sx={{ minWidth: 150 }} >
						<InputLabel id='bank-selection'>{SELECT_BANK}</InputLabel>
						<Select labelId='bank-selection' autoWidth label={SELECT_BANK} {...formik.getFieldProps('bank')}>
							{sharedDataState.banks
                .filter(({ is_active: isActive }) => isActive)
                .map(({ bank_code: value, bank_name_en: label }) => (
								<MenuItem key={value as string} value={value}>
									{label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl size='small' sx={{ minWidth: 150 }}>
						<InputLabel id='status-selection'>{SELECT_STATUS}</InputLabel>
						<Select labelId='status-selection' autoWidth label={SELECT_STATUS} {...formik.getFieldProps('status')}>
							{Object.entries(RECON_FILTER_STATUSES).map(([value, label]) => (
								<MenuItem key={value as string} value={value}>
									{label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
          <FormControl size='small' sx={{ minWidth: 150 }}>
						<InputLabel id='account-type-selection'>Phân loại tài khoản</InputLabel>
						<Select labelId='account-type-selection' autoWidth label="Phân loại tài khoản" {...formik.getFieldProps('accountType')}>
              <MenuItem key="personal" value="1">
                Cá nhân
              </MenuItem>
              <MenuItem key="organization" value="2">
                Tổ chức
              </MenuItem>
						</Select>
					</FormControl>
          <FormControl sx={{ minWidth: 150 }}>
						<TextField fullWidth size='small' label="Mã khách hàng" {...formik.getFieldProps('cifNumber')} />
					</FormControl>
          <FormControl sx={{ minWidth: 150 }}>
						<TextField fullWidth size='small' label="Mã đối tác" {...formik.getFieldProps('smeNumber')} />
					</FormControl>
					<TimeFilter
            ref={timeFilterRef}
            enableDateRangeForCustomOption
            handleFilterOptionChange={handleFilterOptionChange}
            initialValue={formik.values.selectedTimeFilter as FilterEnum.Today}
          />
				</Box>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(1, 1fr)',
						gap: 1,
						mt: 1,
					}}>
					<Stack direction='row' spacing={2} sx={{ pt: 1, pb: 1 }} alignItems='right' justifyContent={'flex-end'}>
						<Button variant='outlined' sx={{ mr: 2 }} onClick={handleFilterReset}>
							{RESET}
						</Button>
						<Button variant='contained' onClick={formik.submitForm}>
							{SEARCH}
						</Button>
					</Stack>
				</Box>
			</Card>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					mb: 2,
					mt: 2,
				}}>
				<Search
          value={formik.values.partner}
          placeholder={FIND_ACCOUNT_IDENTIFIER}
          handleTextInput={(value) => formik.setFieldValue('partner', value)}
        />
				<Box>
					<DownloadReport
						handleDownloadReport={handleDownloadReport}
					/>
					<ActionItem title={REFRESH} icon={Refresh} onClick={() => onChangePage(paginationModel)} />
				</Box>
			</Box>
			<Grid container spacing={3}>
				<Grid item xs={12} md={12} lg={12}>
					<Card>
						<CustomDataGrid
							tableKey='VIRTUAL_ACCOUNT_TABLE'
							loading={isLoading}
							rowCount={partnerSummary?.total_record || 0}
							rows={partners}
							columns={columns}
							getRowId={(row: IAccountBalanceHistory) => `${row.account_name}${row.occurring_date}${row.bank_code}${row.cif_number}`}
              pageSizeOptions={[10, 50, 100]}
							paginationModel={paginationModel}
							onPaginationModelChange={onChangePage}
							summaryRows={[
								{ key: TOTAL_INITIAL_BALANCE, value: formatDecimal(partnerSummary?.previous_current_amount || 0) },
								{ key: TOTAL_DEBIT, value: formatDecimal(partnerSummary?.total_debit_amount || 0) },
								{ key: TOTAL_CREDIT, value: formatDecimal(partnerSummary?.total_credit_amount || 0) },
								{ key: TOTAL_SYSTEM_END_BALANCE, value: formatDecimal(partnerSummary?.balance_current_amount || 0) },
								{
									key: TOTAL_END_OF_PERIOD_BALANCE,
									value: formatDecimal(partnerSummary?.aggregated_amount || 0),
								},
							]}
						/>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};

export default AccountIdentifier;
