import { ActionItem }	from '../ActionItem';
import {
	GetApp
} from '@mui/icons-material';
import { DownloadReportProps } from './types';

export const DownloadReport = (props: { handleDownloadReport: () => void }) => {

	return (
		<>
			<ActionItem title='Tải báo cáo' icon={GetApp} onClick={() => props.handleDownloadReport()} />
		</>
	);
};

