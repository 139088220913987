/** @jsxImportSource @emotion/react */
import { Box, Typography, TextField, Stack, InputAdornment, IconButton, Card, alpha, useTheme } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { APP_TITLE, PAGE_TITLE_LOGIN } from '../../utils/constants';
import { bgGradient } from '../../theme/css';
import { SYS0131, SYS0150 } from '../../utils/errorCodes';
import { useUserActions } from '../../hooks/useUserActions';
export const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const userActions = useUserActions();
  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
      userName: '',
      password: '',
    },
    onSubmit: async (values, { setErrors }) => {
      try {
        await userActions.login(values.userName, values.password)
      } catch(exception: any) {
        const error = exception?.response?.data?.errors?.[0];
        const errorCode = error?.code

        switch (errorCode) {
          case SYS0131:
            setErrors({ userName: '', password: 'Sai mật khẩu'  });
            break;
          case SYS0150:
            setErrors({ password: '', userName: 'Sai tài khoản' });
            break;
          default:
            setErrors({ password: 'Sai mật khẩu', userName: 'Sai tài khoản' });
        }
      }
    },
    validationSchema: Yup.object({
      userName: Yup.string().required('Vui lòng nhập địa chỉ email'),
      password: Yup.string().required('Vui lòng nhập password'),
    })
  });

	const handleSubmit = (e: any) => {
		e.preventDefault();
    formik.handleSubmit();
	};

  const renderForm = (
    <>
      <Stack spacing={3} sx={{ my: 3 }}>
        <TextField
          {...formik.getFieldProps('userName')}
          label="Địa chỉ email"
          error={!!(formik.touched.userName && formik.errors.userName)}
          helperText={formik.touched.userName && formik.errors.userName}
        />
        <TextField
          type={showPassword ? 'text' : 'password'}
          label="Mật khẩu"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...formik.getFieldProps('password')}
          error={!!(formik.touched.password && formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 3 }}>
        <Link variant="subtitle2" underline="hover">
          Quên mật khẩu?
        </Link>
      </Stack> */}

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
        onClick={handleSubmit}
      >
        Đăng nhập
      </LoadingButton>
    </>
  );

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>
						{PAGE_TITLE_LOGIN} | {APP_TITLE}
					</title>
				</Helmet>
			</HelmetProvider>
			<Box
        sx={{
          ...bgGradient({
            color: alpha(theme.palette.background.default, 0.9),
            imgUrl: '/assets/background/overlay_4.jpg',
          }),
          height: 1,
        }}
      >
        <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
          <Card
            sx={{
              p: 5,
              width: 1,
              maxWidth: 420,
            }}
          >
            <Typography variant="h4" sx={{ mb: 3 }}>Đăng nhập</Typography>
            {/* <Typography variant="body2" sx={{ mt: 2, mb: 5 }}>
              Chưa có tài khoản?
              <Link variant="subtitle2" sx={{ ml: 0.5 }}>
                Đăng ký
              </Link>
            </Typography> */}
            {renderForm}
          </Card>
        </Stack>
      </Box>
		</>
	);
};
