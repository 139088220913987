import Request from './Request';

const PATH = '/api/v1/banking/bo/master-accounts/history';

export interface IGetAllMasterAccountParams {
  date: string;
};

export const getAllMasterAccounts = (params?: IGetAllMasterAccountParams) => Request.get(PATH, { params });

export default {
  getAllMasterAccounts,
};