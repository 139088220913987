/** @jsxImportSource @emotion/react */
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { CircularProgress, Box, TableContainer, Table, TableBody, TableRow, TableCell, Paper, Typography } from '@mui/material';
import { TOTALS } from './constants';
import { useEffect, useState } from 'react';
import { ICustomDataGridProps } from './types';
import { useRecoilState } from 'recoil';
import authStateAtom from '../../../recoil/atoms/authStateAtom';

export const CustomDataGrid = (props: ICustomDataGridProps) => {
	const [authState] = useRecoilState(authStateAtom);
	const tableKey = `${props.tableKey}_${authState.username}_STATE`; //Make sure we maintain user wise state
	const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>();
	const CustomToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
			</GridToolbarContainer>
		);
	};

	const CustomLoadingOverlay = () => {
		return null; //We do this to disabled the default datagrid loading overlay which is crap!
	}

	useEffect(() => {
		const storedState = localStorage.getItem(tableKey);
		if (storedState) {
			setColumnVisibilityModel(JSON.parse(storedState));
		}
	}, []);

	return (
		<Box position='relative'>
			{props.loading && (
				<Box
					position='absolute'
					top={0}
					left={0}
					width='100%'
					height='100%'
					display='flex'
					justifyContent='center'
					alignItems='center'
					bgcolor='rgba(255, 255, 255, 0.7)'
					zIndex={2}>
					<CircularProgress />
				</Box>
			)}
			<Box zIndex={1}>
				<DataGrid
					{...props}
					pageSizeOptions={props?.pageSizeOptions || [10]}
					paginationMode='server'
					slots={{
						toolbar: CustomToolbar,
						loadingOverlay: CustomLoadingOverlay,
					}}
					columnVisibilityModel={columnVisibilityModel}
					onColumnVisibilityModelChange={(newModel) =>
						{
							setColumnVisibilityModel(newModel)
							localStorage.setItem(tableKey, JSON.stringify(newModel));
						}
					}
				/>
			</Box>
      {props.summaryRows && props.summaryRows.length > 0 && (
      <Box sx={{ mt:2, mb:2 }}>
        <Typography variant='subtitle1' sx={{ml:2, mb: 1}}>{TOTALS}</Typography>
        <TableContainer sx={{ width: 450 }} component={Paper}>
          <Table size='small'>
            <TableBody>
              {props.summaryRows.map((row: any) => (
                <TableRow
                  key={row.key}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>{row.key}</TableCell>
                  <TableCell align='right'>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      )}
		</Box>
	);
};
