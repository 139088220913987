import { Divider, Menu, MenuItem } from '@mui/material';
import {SignOut } from '../../Actions';

interface DefaultMenuProps {
	isMenuOpen: boolean;
	handleMenuClose: () => void;
  handleSignOut?: () => void;
	anchorEl: HTMLElement | null;
}

export const DropdownMenu = ({ isMenuOpen, handleMenuClose, anchorEl }: DefaultMenuProps) => {	
	return (<Menu anchorEl={anchorEl} id='primary-search-account-menu' keepMounted open={isMenuOpen} onClose={handleMenuClose}>
		<Divider />		
		<MenuItem onClick={handleMenuClose}>
			<SignOut />
		</MenuItem>
	</Menu>)
};
