export const trimEmptyField = <T>(obj: {
  [key in string]: any
}): T => {
  const objectKeys = Object.keys(obj);

  return objectKeys.reduce((sanitizedObject, key) => {
    if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
      return {
        ...sanitizedObject,
        [key]: obj[key],
      }
    }

    return sanitizedObject;
  }, {}) as T;
}