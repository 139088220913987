import { TextField, InputAdornment, FormControl, Paper } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { debounce } from 'lodash';
import React, { useState, useCallback } from 'react';

export type SearchProps = {
	placeholder: string;
	handleTextInput: (value: string) => void;
  value?: string;
};

export const Search = ({ placeholder, handleTextInput, value }: SearchProps) => {
	const [inputValue, setInputValue] = useState(value ?? '');

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value);
		handleTextInput(event.target.value);
	};

	return (
		<FormControl size='small' sx={{ minWidth: 400, mt: 1 }} component={Paper}>
			<TextField
				size='small'
				sx={{ flex: 1 }}
				label={placeholder}
				value={value ?? inputValue}
				onChange={handleChange}
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<SearchIcon />
						</InputAdornment>
					),
				}}
			/>
		</FormControl>
	);
};
