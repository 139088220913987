import Request from './Request';
import { EPaymentStatus } from "../types/EPaymentStatus";
import { CurrencyCode } from "../types/Currency";

const PATH = '/api/v1/core/bo/transfer';

export interface ITransactionQuery {
  start?: string;
  end?: string;
  account?: string;
  page?: number;
  limit?: number;
  status?: string;
  name?: string;
  trx?: string;
  not?: string;
};

enum PaymentMethod {
  Empty = 'empty',
  InA = 'IN-A',
  InC = 'IN-C',
  NapasA = 'NAPAS-A',
  NapasC = 'NAPAS-C',
  Citad = 'CITAD',
}

export interface ITransaction {
  transaction_number: string;
  payment_number: string;
  bank_transaction_number: string;
  transaction_code: string;
  transaction_type: 'DR' | 'CR';
  payment_use_case: string;
  payment_method: PaymentMethod;
  account_number: string;
  account_name: string;
  counterpart_account_number: string;
  beneficiary_bank_code: string;
  transaction_amount: number;
  adjusted_amount: number;
  currency_code: CurrencyCode;
  transaction_at: string;
  transaction_note: string;
  payment_status: EPaymentStatus;
  payment_failed_reason: string;
}


export const getTransactions = async (params: ITransactionQuery) => Request.get(`${PATH}/history`, { params });
export const getTransactionsSummary = async (params: ITransactionQuery) => Request.get(`${PATH}/summary`, { params });
