import { atom } from "recoil";

import localStorageEffect from "../effects/localStorageSyncEffect";
import { IBank } from "../../types/IBank";
import { IPartners } from "../../types/IPartners";
import { loadBankListUntilEmpty } from "../../utils/loadBankListUntilEmpty";
import { IMasterAccount } from "../../types/IMasterAccount";
import { SHARED_DATA_ATOM_KEY } from "../../utils/constants";

const sharedDataAtom = atom<{ banks: IBank[], isDataPersisted: boolean, partners: IPartners[], masterAccounts: IMasterAccount[] }>({
  key: SHARED_DATA_ATOM_KEY,
  default: {
    banks: [],
    isDataPersisted: false,
    partners:[],
    masterAccounts:[]
  },
  effects: [],
});

export default sharedDataAtom; 
