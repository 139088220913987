import { PaymentMethodTyped } from "../services/Payment";

// APP TEXT
export const APP_TITLE = 'Recon Portal';
export const APP_DESCRIPTION = 'Recon Portal';
export const FOOTER_TEXT = `${new Date().getFullYear()} - Finesys Pte Ltd. All Rights Reserved`;
// PAGES TITLE
export const PAGE_TITLE_ACCOUNT_BALANCE = 'Số dư tài khoản';
export const PAGE_TITLE_CONTROL_REPORTS = 'Control Reports';
export const PAGE_TITLE_LOGIN = 'Login';
export const SIGNOUT = 'Signout';

// GLOBAL NAVIGATION
export const NAV_ACCOUNT = 'Tài khoản';
export const NAV_PARTNER = 'Đối tác';
export const NAV_ACCOUNT_IDENTIFIER = 'Tài khoản định danh';
export const NAV_TRANSACTION_HISTORY = 'Lịch sử giao dịch';
export const NAV_RECON_HISTORY = 'Báo cáo đối soát';
export const NAV_GALAXY_PAY_PAYMENT_REQUEST_LIST = 'Quản lý giao dịch';
export const NAV_GALAXY_PAY_PAYMENT_REQUEST_RECON = 'Báo cáo đối soát';
export const NAV_GALAXY_PAY_PAYMENT_REQUEST_ACCOUNTING_REPORT = 'Báo cáo hạch toán';

// UI CONSTANTS
export const FOOTER_HEIGHT = 30;
export const HEADER_HEIGHT = 60;
export const DRAWER_WIDTH = 250;

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DISPLAY_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss'
export const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY';

// Atoms
export const AUTH_ATOM_KEY = 'AUTH/ATOM';
export const PAGE_ATOM_KEY = 'PAGE/ATOM';
export const APPLICATION_STATE_ATOM_KEY = 'APPLICATION_STATE/ATOM';
export const SHARED_DATA_ATOM_KEY = 'SHARED_DATA/ATOM';

//Filter fields
export const RECON_FILTER_STATUSES = {
  matched: 'Khớp',
  unmatched: 'Không khớp',
  notReconYet: 'Chờ đối soát',
};

export const ACCOUNT_TYPES = {
  organization: 'Tổ chức',
  individual: 'Cá nhân'
};

export const DOWNLOAD = 'Tải báo cáo';
export const RESET = 'Nhập lại';
export const SEARCH = 'Tìm kiếm';
export const REFRESH = 'Làm mới';
export const LAST_UPDATED_AT = 'Dữ liệu được cập nhật lúc';
export const TRADING_START_DATE = 'Ngày bắt đầu giao dịch';
export const TRADING_END_DATE = 'Ngày kết thúc giao dịch';
export const FIND_TRANSACTION = 'Tìm theo mã giao dịch của hệ thống hoặc ngân hàng'

//Common table column headers
export const DEBIT = 'Ghi nợ (Debit)';
export const CREDIT = 'Ghi có (Credit)';
export const SYSTEM_END_BALANCE = 'Số dư cuối kỳ theo hệ thống';
export const END_OF_PERIOD_BALANCE = 'Số dư cuối kỳ theo công thức';
export const END_OF_PERIOD_BALANCE_TOOLTIP = 'Số dư = Số dư đầu kỳ + Số ghi có - Số ghi nợ';
export const BALANCE_COMPARISON = 'Đối chiếu số dư';
export const BALANCE_COMPARISON_TOOLTIP = 'So sánh Số dư cuối kỳ theo hệ thống và số dư cuối kỳ theo công thức';
export const SELECT_PARTNER = 'Chọn đối tác'
export const SELECT_BANK = 'Chọn ngân hàng'
export const TOTAL_BALANCE = 'Tổng số dư';
export const TOTAL_INITIAL_BALANCE = 'Tổng số dư đầu kỳ';
export const TOTAL_DEBIT = 'Tổng ghi nợ';
export const TOTAL_CREDIT = 'Tổng ghi có';
export const TOTAL_SYSTEM_END_BALANCE = 'Tổng số dư cuối kỳ theo hệ thống';
export const TOTAL_END_OF_PERIOD_BALANCE = 'Tổng số dư cuối kỳ theo công thức';
export const PARTNER_NAME = 'Tên đối tác';
export const PARTNER_CODE = 'Mã đối tác';
export const PARTNER_BANK_CODE = 'Ngân hàng';
export const SELECT_ACCOUNT_NUMBER = 'Chọn số tài khoản'
export const SELECT_ACCOUNT_TYPE = 'Phân loại tài khoản'
export const ACCOUNT_NUMBER = 'Số tài khoản'
export const CATEGORY = 'Phân loại'
export const IDENTIFIER_ACCOUNT_NUMBER = 'Số tài khoản định danh'
export const INITIAL_BALANCE = 'Số dư đầu kỳ';
export const SELECT_STATUS = 'Đối chiếu số dư';
export const CURRENCY = 'Tiền tệ';

export const PAYMENT_METHOD_LABEL: {
  [key in typeof PaymentMethodTyped[number]]?: string
} = {
  // 'bank_transfer': 'Chuyển khoản',
  'credit_card': 'Thẻ quốc tế',
  'domestic_card': 'Thẻ nội địa',
  'ewallet_galaxypay': 'Galaxypay',
  'ewallet_momo': 'MoMo',
  'ewallet_viettel': 'ViettelPay',
  'ewallet_zalopay': 'ZaloPay',
  'qrpay': 'QR Pay',
};

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE = 0;