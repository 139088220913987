import Request from './Request';

export interface IGenerateReportParams {
  report_type?: 'va_balance_history' | 'sme_balance_history' | 'transaction' | 'reconciliation';
  file_type?: 'xlsx';
  report_conditions?: any;
};

export const generateReport = async (params: IGenerateReportParams) => {
  return Request.post('/api/v1/report/download', params)
}
