import Request from './Request';

const LOGIN_PATH = '/api/v1/tenant/bo/auth';
const LOGOUT_PATH = '/api/v1/tenant/bo/logout';//TODO returns 404

export const loginRequest = async ( userName: string, password: string ) => {
  const result = await Request.post(LOGIN_PATH, {
    username: userName,
    password,
  })

  return result;
};

export const logoutRequest = async () => {
  const result = await Request.post(LOGOUT_PATH)
  return result;
};

export default {
  loginRequest,
};