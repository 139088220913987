/** @jsxImportSource @emotion/react */
import { Box, Button, Card, Grid, Select, Stack, MenuItem, FormControl, InputLabel, Link } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { GridColDef } from '@mui/x-data-grid';
import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
	APP_TITLE,
	DATE_FORMAT,
	DISPLAY_DATE_TIME_FORMAT,
	RESET,
	SEARCH,
	REFRESH,
	SELECT_PARTNER,
	SELECT_BANK,
	SELECT_STATUS,
	PARTNER_NAME,
	PARTNER_CODE,
	PARTNER_BANK_CODE,
	INITIAL_BALANCE,
	DEBIT,
	CREDIT,
	SYSTEM_END_BALANCE,
	END_OF_PERIOD_BALANCE,
	BALANCE_COMPARISON,
	TOTAL_DEBIT,
	TOTAL_CREDIT,
	TOTAL_SYSTEM_END_BALANCE,
	TOTAL_INITIAL_BALANCE,
	TOTAL_END_OF_PERIOD_BALANCE,
	END_OF_PERIOD_BALANCE_TOOLTIP,
	BALANCE_COMPARISON_TOOLTIP,
	CURRENCY,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE,
} from '../../utils/constants';
import { PAGE_TITLE, FIND_PARTNERS } from './constants';
import Logger from '../../services/Logger';
import sharedDataAtom from '../../recoil/atoms/sharedDataAtom';
import { formatDecimal } from '../../utils/formatCurrency';
import {
	IGetPartnerListQuery,
	IPartnerSummary,
	IPartner,
	getPartnerList,
	getPartnerSummary,
} from '../../services/Partner';
import { Refresh } from '@mui/icons-material';
import { DownloadReport } from '../../components/Actions/DownloadReport';
import pageStateAtom from '../../recoil/atoms/pageStateAtom';
import { ITimeFilterRef, TimeFilter } from '../../components/Filter/TimeFilter';
import { ActionItem } from '../../components/Actions/ActionItem';
import moment from 'moment';
import { CustomDataGrid } from '../../components/DataGrid/CustomDataGrid';
import { Search } from '../../components/Filter/Search';
import { CustomColumnHeader } from '../../components/DataGrid/CustomColumnHeader';
import { TimeFilterResultType } from '../../components/Filter/TimeFilter/types';
import { DownloadReportResultType } from '../../components/Actions/DownloadReport/types';
import { CurrencyCode } from '../../types/Currency';
import { useLocation } from 'react-router-dom';
import { FilterEnum } from '../../components/Filter/TimeFilter/constants';
import { IAccountBalanceHistory, IAccountBalanceSummary, IGetAccountBalanceHistoryQueryParameters, getAccountBalanceHistory, getAccountBalanceSummary } from '../../services/Balance';
import { extractReponse } from '../../utils/responseExtractor';
import { generateReport } from '../../services/Report';
import applicationStateAtom from '../../recoil/atoms/applicationStateAtom';
import { IAssets, generateDownloadUrlForPresignLink } from '../../services/Download';
import { useDownloadHandler } from '../../hooks/useDownloadHandler';
import { trimEmptyField } from '../../utils/trimEmptyField';


const mapValuesToListRequestPayload = (values: any) => {
  const { bank, partner, status, selectedTimeFilter, selectedDate, selectedEndDate } = values;

  const mapDateRange = (_start: string, _end: string) => {
    if (_start && _end) {
      return {
        start: _start,
        end: _end,
      }
    }

    if (_start) {
      return {
        date: _start,
      };
    }

    return {
      date: moment().format(DATE_FORMAT).toString(),
    };
  }

  return {
    sme: partner,
    bank,
    status,
    ...mapDateRange(selectedDate?.format(DATE_FORMAT).toString(), selectedEndDate?.format(DATE_FORMAT).toString())
  };
};

const mapListReqestPayloadToDownloadPayload = (values: any) => {
  const { sme, bank, status, start, end, date } = values;
  const ifUserSelectCustomDateRange = (_start: string, _end: string) => _start && _end;
  const today = moment().format(DATE_FORMAT).toString();

  return {
    sme_number: sme,
    bank_code: bank,
    aggregated_status: status,
    start_date: start,
    end_date: end,
    occurring_date: date || (ifUserSelectCustomDateRange(start, end) ? undefined : today),
    cif_type: 3
  };
};

const FILTER_STATUSES = {
  matched: 'Khớp',
  unmatched: 'Không khớp',
  missed: 'Chờ đối chiếu',
};

const columns: GridColDef[] = [
	{
		field: 'account_name',
		headerName: PARTNER_NAME,
		flex: 1,
    minWidth: 300,
	},
	{
		field: 'sme_number',
		headerName: PARTNER_CODE,
		flex: 1,
    minWidth: 200,
    renderCell(params) {
      const { row }: { row: IAccountBalanceHistory } = params;

      return <Link href={`/virtual-account?sme=${row.sme_number}&isApplyingFilterValue=true`}>{row.sme_number}</Link>;
    },
	},
  {
		field: 'business_id',
		headerName: 'Business ID',
		flex: 1,
    minWidth: 200,
	},
	{
		field: 'bank_code',
		headerName: PARTNER_BANK_CODE,
		flex: 1,
    minWidth: 150,
	},
	{
		field: 'previous_current_amount',
		headerName: INITIAL_BALANCE,
		flex: 1,
    align: 'right',
    headerAlign: 'right',
    minWidth: 150,
    valueFormatter(params) {
			return params.value && formatDecimal(params.value);
		},
	},
	{
		field: 'total_debit_amount',
		headerName: DEBIT,
		flex: 1,
		align: 'right',
		headerAlign: 'right',
    minWidth: 150,
    valueFormatter(params) {
			return params.value && formatDecimal(params.value);
		},
	},
	{
		field: 'total_credit_amount',
		headerName: CREDIT,
		flex: 1,
		align: 'right',
		headerAlign: 'right',
    minWidth: 150,
    valueFormatter(params) {
			return params.value && formatDecimal(params.value);
		},
	},
	{
		field: 'balance_current_amount',
		headerName: SYSTEM_END_BALANCE,
		flex: 1,
		align: 'right',
		headerAlign: 'right',
    minWidth: 200,
    valueFormatter(params) {
			return params.value && formatDecimal(params.value);
		},
	},
	{
		field: 'aggregated_amount',
		headerName: END_OF_PERIOD_BALANCE,
		align: 'right',
		headerAlign: 'right',
		renderHeader: () => CustomColumnHeader(END_OF_PERIOD_BALANCE, END_OF_PERIOD_BALANCE_TOOLTIP),
		flex: 1,
		minWidth: 200,
		valueFormatter(params) {
			return params.value && formatDecimal(params.value);
		},
	},
	{
		field: 'currency_code',
		headerName: CURRENCY,
		align: 'center',
		headerAlign: 'center',
		valueFormatter(params) {
			return CurrencyCode[params.value as keyof typeof CurrencyCode];
		},
	},
	{
		field: 'aggregated_status',
		headerName: BALANCE_COMPARISON,
		renderHeader: () => CustomColumnHeader(BALANCE_COMPARISON, BALANCE_COMPARISON_TOOLTIP),
		flex: 1,
    minWidth: 150,
		valueGetter(params) {
      const { row }: { row: IAccountBalanceHistory } = params;

      if (row.aggregated_status === 'matched') {
        return 'Khớp';
      }

      if (row.aggregated_status === 'unmatched') {
        return 'Không khớp';
      }

      if (row.occurring_date) {
        return 'Chờ đối chiếu';
      }

      return '-';
		},
	},
];

export const Partner = () => {	
	const [searchParams, setSearchParams] = useSearchParams({});
	const [pageState, setPageState] = useRecoilState(pageStateAtom);
	const [partners, setPartners] = useState<IAccountBalanceHistory[]>([]);
	const [partnerSummary, setPartnerSummary] = useState<IAccountBalanceSummary>();
	const [isLoading, setIsLoading] = useState(true);
  const timeFilterRef = useRef<ITimeFilterRef>({ reset() {}, });
	const [paginationModel, setPaginationModel] = useState({
		pageSize: searchParams.get('limit') ? Number(searchParams.get('limit')) : DEFAULT_PAGE_SIZE,
		page: searchParams.get('page') ? Number(searchParams.get('page')) - 1 : DEFAULT_PAGE,
	});
  const isApplyingFilterValue = useRef(searchParams.get('isApplyingFilterValue') || false);
	const lastUpdatedRef = useRef('');
	const [sharedDataState] = useRecoilState(sharedDataAtom);
  const exportAndDownload = useDownloadHandler();

	const fetchData = useCallback(
		async (params: IGetAccountBalanceHistoryQueryParameters) => {
			try {
				if (!sharedDataState.banks.length || !sharedDataState.partners.length) {
					return;
				}

				setIsLoading(true);

				const response = await getAccountBalanceHistory({
          ...params,
          type: '3',
        });

				const summaryResponse = await getAccountBalanceSummary({
					...params,
          type: '3',
				});

        const partners = extractReponse<IAccountBalanceHistory[]>(response)?.map(({ bank_code, ...rest }) => {
          return {
            ...rest,
            bank_code: sharedDataState.banks.find(bank => bank.bank_code === bank_code)!.bank_name_en,
          }
        });
        const summary = extractReponse<IAccountBalanceSummary[]>(summaryResponse)?.[0];


				lastUpdatedRef.current = moment().format(DISPLAY_DATE_TIME_FORMAT).toString();

				setPartners(partners || []);
				setPartnerSummary(summary);
			} catch (exception) {
				Logger.log(exception);
			} finally {
				setIsLoading(false);
			}
		},
		[sharedDataState.banks.length],
	);

	const formik = useFormik<{
		status: 'matched' | 'unmatched' | 'missed' | '';
    selectedDate: moment.Moment;
    selectedEndDate: undefined | moment.Moment;
		bank: string;
		partner: string;
    selectedTimeFilter: undefined | string;
	}>({
		initialValues: {
			status: searchParams.get('status') as 'matched' | 'unmatched' | 'missed' || '',
			bank: searchParams.get('bank') || '',
			partner: searchParams.get('sme') || '',
      selectedDate: moment(searchParams.get('date') || searchParams.get('date') || undefined),
      selectedEndDate: searchParams.get('end') ? moment(searchParams.get('end')) : undefined,
      selectedTimeFilter: searchParams.get('selectedTimeFilter') || FilterEnum.Today,
		},
		onSubmit: () => {
      isApplyingFilterValue.current = true;
			onChangePage({
				page: 0,
				pageSize: 10,
			});
		},
	});

	useEffect(() => {
		setPageState({
			...pageState,
			pageTitle: PAGE_TITLE,
		});
		fetchData({
			page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      ...(isApplyingFilterValue.current ? mapValuesToListRequestPayload(formik.values) : {
        date: moment().format(DATE_FORMAT).toString(),
      }),
		});
    setSearchParams(trimEmptyField({
			page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      seletedTimeFilter: formik.values.selectedTimeFilter,
      isApplyingFilterValue: isApplyingFilterValue.current,
      ...(isApplyingFilterValue.current ? mapValuesToListRequestPayload(formik.values) : {
        date: moment().format(DATE_FORMAT).toString(),
      }),
		}));
	}, [fetchData]);

	const onChangePage = (nextPaginationModel: typeof paginationModel) => {
		setPaginationModel(nextPaginationModel);

		fetchData({
			page: nextPaginationModel.page + 1,
      limit: nextPaginationModel.pageSize,
      ...(isApplyingFilterValue.current ? mapValuesToListRequestPayload(formik.values) : {
        date: moment().format(DATE_FORMAT).toString(),
      }),
		});
		setSearchParams(trimEmptyField({
			page: nextPaginationModel.page + 1,
      limit: nextPaginationModel.pageSize,
      seletedTimeFilter: formik.values.selectedTimeFilter,
      isApplyingFilterValue: isApplyingFilterValue.current,
      ...(isApplyingFilterValue.current ? mapValuesToListRequestPayload(formik.values) : {
        date: moment().format(DATE_FORMAT).toString(),
      }),
		}));
	};

	const handleFilterOptionChange = ({ option, selectedDate, selectedEndDate }: { option: string; selectedDate: moment.Moment; selectedEndDate?: moment.Moment; }) => {
    formik.setFieldValue('selectedTimeFilter', option);

    if (option !== FilterEnum.Custom) {
      formik.setFieldValue('selectedDate', selectedDate);
      formik.setFieldValue('selectedEndDate', undefined);
    } else {
      formik.setFieldValue('selectedDate', selectedDate);
      formik.setFieldValue('selectedEndDate', selectedEndDate);
    }
	};

	const handleDownloadReport = async () => {
      exportAndDownload({
        reportType: 'sme_balance_history',
        reportConditions: isApplyingFilterValue.current ? mapListReqestPayloadToDownloadPayload(mapValuesToListRequestPayload(formik.values)) : {
          occurring_date: moment().format(DATE_FORMAT).toString(),
          cif_type: 3,
        }
      })
	};

	const handleFilterReset = () => {
		formik.setValues({
      status: '',
      bank: '',
      partner: '',
      selectedDate: moment(),
      selectedEndDate: undefined,
      selectedTimeFilter: FilterEnum.Today,
    })
    isApplyingFilterValue.current = false;
    timeFilterRef.current.reset();
    onChangePage({
      page: 0,
      pageSize: 10,
    });
	}

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>
						{PAGE_TITLE} | {APP_TITLE}
					</title>
				</Helmet>
			</HelmetProvider>
			<Card sx={{ padding: 2, paddingTop: 3 }}>
				<Box sx={{ display: 'grid', gap: 3, gridTemplateColumns: 'repeat(4, 1fr)' }}>
					<FormControl size='small' sx={{ minWidth: 150 }}>
						<InputLabel id='bank-selection'>{SELECT_BANK}</InputLabel>
						<Select labelId='bank-selection' autoWidth label={SELECT_BANK}  {...formik.getFieldProps('bank')}>
							{sharedDataState.banks
                .filter(({ is_active: isActive }) => isActive)
                .map(({ bank_code: value, bank_name_en: label }) => (
								<MenuItem key={value as string} value={value}>
									{label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl size='small' sx={{ minWidth: 150 }}>
						<InputLabel id='status-selection'>{SELECT_STATUS}</InputLabel>
						<Select labelId='status-selection' autoWidth label={SELECT_STATUS} {...formik.getFieldProps('status')}>
							{Object.entries(FILTER_STATUSES).map(([value, label]) => (
								<MenuItem key={value as string} value={value}>
									{label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TimeFilter
            ref={timeFilterRef}
            enableDateRangeForCustomOption
            handleFilterOptionChange={handleFilterOptionChange}
            initialValue={formik.values.selectedTimeFilter as FilterEnum.Today}
          />
				</Box>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(1, 1fr)',
						gap: 1,
						mt: 1,
					}}>
					<Stack direction='row' spacing={2} sx={{ pt: 1, pb: 1 }} alignItems='right' justifyContent={'flex-end'}>
						<Button variant='outlined' sx={{ mr: 2 }} onClick={handleFilterReset}>
							{RESET}
						</Button>
						<Button variant='contained' onClick={formik.submitForm}>
							{SEARCH}
						</Button>
					</Stack>
				</Box>
			</Card>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					mb: 2,
					mt: 2,
				}}>
				<Search
          placeholder={FIND_PARTNERS}
          handleTextInput={(value) => formik.setFieldValue('partner', value)}
          value={formik.values.partner}
        />
				<Box>
					<DownloadReport
						handleDownloadReport={handleDownloadReport}
					/>
					<ActionItem title={REFRESH} icon={Refresh} onClick={() => onChangePage(paginationModel)} />
				</Box>
			</Box>
			<Grid container spacing={3}>
				<Grid item xs={12} md={12} lg={12}>
					<Card>
						<CustomDataGrid
							tableKey='PARTNER_TABLE'
							loading={isLoading}
							rowCount={partnerSummary?.total_record || 0}
							rows={partners}
							columns={columns}
							getRowId={(row: IAccountBalanceHistory) => `${JSON.stringify(row)}`}
							paginationModel={paginationModel}
							onPaginationModelChange={onChangePage}
              pageSizeOptions={[10, 50, 100]}
							summaryRows={[
								{ key: TOTAL_INITIAL_BALANCE, value: formatDecimal(partnerSummary?.previous_current_amount || 0) },
								{ key: TOTAL_DEBIT, value: formatDecimal(partnerSummary?.total_debit_amount || 0) },
								{ key: TOTAL_CREDIT, value: formatDecimal(partnerSummary?.total_credit_amount || 0) },
								{ key: TOTAL_SYSTEM_END_BALANCE, value: formatDecimal(partnerSummary?.balance_current_amount || 0) },
								{
									key: TOTAL_END_OF_PERIOD_BALANCE,
									value: formatDecimal(partnerSummary?.aggregated_amount || 0),
								},
							]}
						/>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};

export default Partner;
