import { Backdrop, CircularProgress } from "@mui/material";
import { useRecoilState } from "recoil";
import applicationStateAtom from "../recoil/atoms/applicationStateAtom";

const LoadingIndicator = () => {
  // TODO: try to move this somewhere later, because component should not contain side effect
  const [applicationState, setApplicationState] = useRecoilState(applicationStateAtom);

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: 999999 }}
      open={applicationState.isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default LoadingIndicator;
