/** @jsxImportSource @emotion/react */
import { Grid, Typography, Card, Box, Stack, Link } from '@mui/material';
import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Refresh } from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import { APP_TITLE, DISPLAY_DATE_TIME_FORMAT, PAGE_TITLE_ACCOUNT_BALANCE, REFRESH, LAST_UPDATED_AT, DATE_FORMAT } from '../../utils/constants';
import { formatDecimal } from '../../utils/formatCurrency';
import { extractReponse } from '../../utils/responseExtractor';
import { IMasterAccount } from '../../types/IMasterAccount';
import { ActionItem } from '../../components/Actions/ActionItem';
import applicationStateAtom from '../../recoil/atoms/applicationStateAtom';
import sharedDataAtom from '../../recoil/atoms/sharedDataAtom';
import { getAllMasterAccounts } from '../../services/Accounts';
import Logger from '../../services/Logger';
import dayjs from 'dayjs';
import pageStateAtom from '../../recoil/atoms/pageStateAtom';
import { PAGE_TITLE } from './constants';
import { TimeFilter } from '../../components/Filter/TimeFilter';
import { HandleFilterOptionChange } from '../../components/Filter/TimeFilter/types';
import moment from 'moment';

const MasterAccount = ({
	balance,
	bankName,
	accountNumber,
	bankCode
}: {
	balance: number;
	bankName: string;
	accountNumber: string;
	bankCode: string;
}) => (
	<Grid item xs={12} md={4} lg={4}>
		<Card
			sx={{
				p: 4,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				height: 160,
			}}>
			<Typography variant='h4'>{formatDecimal(balance)}</Typography>
			<Typography variant='body1'>{bankName}</Typography>
			<Link variant='body1' href={`/partners?isApplyingFilterValue=true&bank=${bankCode}`}>{accountNumber}</Link>
		</Card>
	</Grid>
);

export const Account = () => {
	const [masterAccounts, setMasterAccounts] = useState<IMasterAccount[]>([]);
	const [applicationState, setApplicationState] = useRecoilState(applicationStateAtom);
	const [sharedDataState, setSharedDataState] = useRecoilState(sharedDataAtom);
	const [pageState, setPageState] = useRecoilState(pageStateAtom);
  const [filterValue, setFilterValue] = useState<{ option: string; selectedDate: moment.Moment }>({
    selectedDate: moment(),
    option: 'day',
  });
	const lastUpdatedAtRef = useRef('');

	const fetchData = useCallback(async ({
    date,
  }: {
    date: string;
  }) => {
		try {
			setApplicationState({ ...applicationState, isLoading: true });

			const response = await getAllMasterAccounts({
        date,
      });
			const data = extractReponse<IMasterAccount[]>(response);

			lastUpdatedAtRef.current = dayjs().format(DISPLAY_DATE_TIME_FORMAT).toString();
			setMasterAccounts(data);
		} catch (exception) {
			Logger.log(exception);
		} finally {
			setApplicationState({ ...applicationState, isLoading: false });
		}
	}, []);

	const onClick = () => {
    const { selectedDate } = filterValue;
		fetchData({
      date: selectedDate.format(DATE_FORMAT).toString(),
    });
	};

  const handleFilterChange: HandleFilterOptionChange = (value) => {
    setFilterValue(value);
  }

	useLayoutEffect(() => {
    setPageState({ ...pageState, pageTitle: PAGE_TITLE });
	}, []);

  useLayoutEffect(() => {
    if (filterValue?.option) {
      fetchData({
        date: filterValue.selectedDate.format(DATE_FORMAT).toString(),
      })
    }
  }, [filterValue?.option])

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>
						{PAGE_TITLE} | {APP_TITLE}
					</title>
				</Helmet>
			</HelmetProvider>
			<Box
				pb={2}
				sx={{
					display: 'flex',
          justifyContent: 'space-between'
				}}>
				<Box>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>
						<Typography variant='h5'>{PAGE_TITLE_ACCOUNT_BALANCE}</Typography>
						<ActionItem title={REFRESH} icon={Refresh} onClick={onClick} />
					</Box>
					<Typography variant='body2'>{`${LAST_UPDATED_AT} ${lastUpdatedAtRef.current}`}</Typography>
				</Box>
				<Box sx={{ flexShrink: 1 }}>
					<TimeFilter handleFilterOptionChange={handleFilterChange} />
				</Box>
			</Box>
			<Grid container spacing={3} key={filterValue.option}>
				{masterAccounts.map((masterAccount) => (
					<MasterAccount
						balance={
							masterAccount.payable_amount || 0
						}
						bankName={
							sharedDataState.banks.find(({ bank_code }) => bank_code === masterAccount.bank_code)?.bank_name_en || ''
						}
						accountNumber={masterAccount.payable_account_number}
						key={masterAccount.account_number}
						bankCode={masterAccount.bank_code}
					/>
				))}
			</Grid>
		</>
	);
};
